"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNonNullObject = isNonNullObject;
exports.isPlainObject = isPlainObject;
function isNonNullObject(obj) {
  return obj !== null && typeof obj === "object";
}
function isPlainObject(obj) {
  return obj !== null && typeof obj === "object" && (Object.getPrototypeOf(obj) === Object.prototype || Object.getPrototypeOf(obj) === null);
}