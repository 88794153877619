"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformOperation = transformOperation;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/index");
function transformOperation(operation) {
  var transformedOperation = {
    variables: operation.variables || {},
    extensions: operation.extensions || {},
    operationName: operation.operationName,
    query: operation.query
  };
  // Best guess at an operation name
  if (!transformedOperation.operationName) {
    transformedOperation.operationName = typeof transformedOperation.query !== "string" ? (0, _index.getOperationName)(transformedOperation.query) || undefined : "";
  }
  return transformedOperation;
}