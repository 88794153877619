"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkFetcher = void 0;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var checkFetcher = exports.checkFetcher = function checkFetcher(fetcher) {
  if (!fetcher && typeof fetch === "undefined") {
    throw (0, _index.newInvariantError)(38);
  }
};