"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useReadQuery = useReadQuery;
var React = _interopRequireWildcard(require("react"));
var _index = require("apollo-stack-hubspot/@apollo/client/react/internal/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/index");
var _useSuspenseQuery = require("apollo-stack-hubspot/@apollo/client/react/hooks/useSuspenseQuery");
var _useSyncExternalStore = require("apollo-stack-hubspot/@apollo/client/react/hooks/useSyncExternalStore");
var _useApolloClient = require("apollo-stack-hubspot/@apollo/client/react/hooks/useApolloClient");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function useReadQuery(queryRef) {
  var unwrapped = (0, _index.unwrapQueryRef)(queryRef);
  return (0, _index2.wrapHook)("useReadQuery", _useReadQuery, unwrapped ? unwrapped["observable"]
  // in the case of a "transported" queryRef object, we need to use the
  // client that's available to us at the current position in the React tree
  // that ApolloClient will then have the job to recreate a real queryRef from
  // the transported object
  // This is just a context read - it's fine to do this conditionally.
  // This hook wrapper also shouldn't be optimized by React Compiler.
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/rules-of-hooks
  : (0, _useApolloClient.useApolloClient)())(queryRef);
}
function _useReadQuery(queryRef) {
  (0, _index.assertWrappedQueryRef)(queryRef);
  var internalQueryRef = React.useMemo(function () {
    return (0, _index.unwrapQueryRef)(queryRef);
  }, [queryRef]);
  var getPromise = React.useCallback(function () {
    return (0, _index.getWrappedPromise)(queryRef);
  }, [queryRef]);
  if (internalQueryRef.disposed) {
    internalQueryRef.reinitialize();
    (0, _index.updateWrappedQueryRef)(queryRef, internalQueryRef.promise);
  }
  React.useEffect(function () {
    return internalQueryRef.retain();
  }, [internalQueryRef]);
  var promise = (0, _useSyncExternalStore.useSyncExternalStore)(React.useCallback(function (forceUpdate) {
    return internalQueryRef.listen(function (promise) {
      (0, _index.updateWrappedQueryRef)(queryRef, promise);
      forceUpdate();
    });
  }, [internalQueryRef, queryRef]), getPromise, getPromise);
  var result = (0, _index2.__use)(promise);
  return React.useMemo(function () {
    return {
      data: result.data,
      networkStatus: result.networkStatus,
      error: (0, _useSuspenseQuery.toApolloError)(result)
    };
  }, [result]);
}