"use strict";
"use es6";

/**
 * @deprecated
 * This is not used internally any more and will be removed in
 * the next major version of Apollo Client.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSignalIfSupported = void 0;
var createSignalIfSupported = exports.createSignalIfSupported = function createSignalIfSupported() {
  if (typeof AbortController === "undefined") return {
    controller: false,
    signal: false
  };
  var controller = new AbortController();
  var signal = controller.signal;
  return {
    controller: controller,
    signal: signal
  };
};