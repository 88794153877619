"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MissingFieldError = void 0;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var MissingFieldError = exports.MissingFieldError = /** @class */function (_super) {
  (0, _tslib.__extends)(MissingFieldError, _super);
  function MissingFieldError(message, path, query, variables) {
    var _a;
    // 'Error' breaks prototype chain here
    var _this = _super.call(this, message) || this;
    _this.message = message;
    _this.path = path;
    _this.query = query;
    _this.variables = variables;
    if (Array.isArray(_this.path)) {
      _this.missing = _this.message;
      for (var i = _this.path.length - 1; i >= 0; --i) {
        _this.missing = (_a = {}, _a[_this.path[i]] = _this.missing, _a);
      }
    } else {
      _this.missing = _this.path;
    }
    // We're not using `Object.setPrototypeOf` here as it isn't fully supported
    // on Android (see issue #3236).
    _this.__proto__ = MissingFieldError.prototype;
    return _this;
  }
  return MissingFieldError;
}(Error);