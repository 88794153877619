"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.print = void 0;
var _printer = require("graphql/language/printer");
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/caching/index");
var _getMemoryInternals = require("apollo-stack-hubspot/@apollo/client/utilities/caching/getMemoryInternals");
var printCache;
var print = exports.print = Object.assign(function (ast) {
  var result = printCache.get(ast);
  if (!result) {
    result = (0, _printer.print)(ast);
    printCache.set(ast, result);
  }
  return result;
}, {
  reset: function () {
    printCache = new _index.AutoCleanedWeakCache(_index.cacheSizes.print || 2000 /* defaultCacheSizes.print */);
  }
});
print.reset();
if (process.env.NODE_ENV !== "production") {
  (0, _getMemoryInternals.registerGlobalCache)("print", function () {
    return printCache ? printCache.size : 0;
  });
}