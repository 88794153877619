"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stringifyForDisplay = stringifyForDisplay;
var _makeUniqueId = require("apollo-stack-hubspot/@apollo/client/utilities/common/makeUniqueId");
function stringifyForDisplay(value, space) {
  if (space === void 0) {
    space = 0;
  }
  var undefId = (0, _makeUniqueId.makeUniqueId)("stringifyForDisplay");
  return JSON.stringify(value, function (key, value) {
    return value === void 0 ? undefId : value;
  }, space).split(JSON.stringify(undefId)).join("<undefined>");
}