"use strict";
"use es6";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApolloErrorMessageHandler = void 0;
Object.defineProperty(exports, "InvariantError", {
  enumerable: true,
  get: function () {
    return _invariant.InvariantError;
  }
});
exports.invariant = void 0;
exports.newInvariantError = newInvariantError;
var _invariant = require("apollo-stack-hubspot/internal/ts-invariant/lib/invariant");
var _version = require("apollo-stack-hubspot/@apollo/client/version");
var _global = _interopRequireDefault(require("apollo-stack-hubspot/@apollo/client/utilities/globals/global"));
var _stringifyForDisplay = require("apollo-stack-hubspot/@apollo/client/utilities/common/stringifyForDisplay");
function wrap(fn) {
  return function (message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    if (typeof message === "number") {
      var arg0 = message;
      message = getHandledErrorMsg(arg0);
      if (!message) {
        message = getFallbackErrorMsg(arg0, args);
        args = [];
      }
    }
    fn.apply(void 0, [message].concat(args));
  };
}
var invariant = exports.invariant = Object.assign(function invariant(condition, message) {
  var args = [];
  for (var _i = 2; _i < arguments.length; _i++) {
    args[_i - 2] = arguments[_i];
  }
  if (!condition) {
    (0, _invariant.invariant)(condition, getHandledErrorMsg(message, args) || getFallbackErrorMsg(message, args));
  }
}, {
  debug: wrap(_invariant.invariant.debug),
  log: wrap(_invariant.invariant.log),
  warn: wrap(_invariant.invariant.warn),
  error: wrap(_invariant.invariant.error)
});
/**
 * Returns an InvariantError.
 *
 * `message` can only be a string, a concatenation of strings, or a ternary statement
 * that results in a string. This will be enforced on build, where the message will
 * be replaced with a message number.
 * String substitutions with %s are supported and will also return
 * pretty-stringified objects.
 * Excess `optionalParams` will be swallowed.
 */
function newInvariantError(message) {
  var optionalParams = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    optionalParams[_i - 1] = arguments[_i];
  }
  return new _invariant.InvariantError(getHandledErrorMsg(message, optionalParams) || getFallbackErrorMsg(message, optionalParams));
}
var ApolloErrorMessageHandler = exports.ApolloErrorMessageHandler = Symbol.for("ApolloErrorMessageHandler_" + _version.version);
function stringify(arg) {
  if (typeof arg == "string") {
    return arg;
  }
  try {
    return (0, _stringifyForDisplay.stringifyForDisplay)(arg, 2).slice(0, 1000);
  } catch (_a) {
    return "<non-serializable>";
  }
}
function getHandledErrorMsg(message, messageArgs) {
  if (messageArgs === void 0) {
    messageArgs = [];
  }
  if (!message) return;
  return _global.default[ApolloErrorMessageHandler] && _global.default[ApolloErrorMessageHandler](message, messageArgs.map(stringify));
}
function getFallbackErrorMsg(message, messageArgs) {
  if (messageArgs === void 0) {
    messageArgs = [];
  }
  if (!message) return;
  return "An error occurred! For more details, see the full error text at https://go.apollo.dev/c/err#".concat(encodeURIComponent(JSON.stringify({
    version: _version.version,
    message: message,
    args: messageArgs.map(stringify)
  })));
}