"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DocumentType = void 0;
exports.operationName = operationName;
exports.parser = parser;
exports.verifyDocumentType = verifyDocumentType;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _getMemoryInternals = require("apollo-stack-hubspot/@apollo/client/utilities/caching/getMemoryInternals");
var DocumentType;
(function (DocumentType) {
  DocumentType[DocumentType["Query"] = 0] = "Query";
  DocumentType[DocumentType["Mutation"] = 1] = "Mutation";
  DocumentType[DocumentType["Subscription"] = 2] = "Subscription";
})(DocumentType || (exports.DocumentType = DocumentType = {}));
var cache;
function operationName(type) {
  var name;
  switch (type) {
    case DocumentType.Query:
      name = "Query";
      break;
    case DocumentType.Mutation:
      name = "Mutation";
      break;
    case DocumentType.Subscription:
      name = "Subscription";
      break;
  }
  return name;
}
// This parser is mostly used to safety check incoming documents.
function parser(document) {
  if (!cache) {
    cache = new _index2.AutoCleanedWeakCache(_index2.cacheSizes.parser || 1000 /* defaultCacheSizes.parser */);
  }
  var cached = cache.get(document);
  if (cached) return cached;
  var variables, type, name;
  (0, _index.invariant)(!!document && !!document.kind, 62, document);
  var fragments = [];
  var queries = [];
  var mutations = [];
  var subscriptions = [];
  for (var _i = 0, _a = document.definitions; _i < _a.length; _i++) {
    var x = _a[_i];
    if (x.kind === "FragmentDefinition") {
      fragments.push(x);
      continue;
    }
    if (x.kind === "OperationDefinition") {
      switch (x.operation) {
        case "query":
          queries.push(x);
          break;
        case "mutation":
          mutations.push(x);
          break;
        case "subscription":
          subscriptions.push(x);
          break;
      }
    }
  }
  (0, _index.invariant)(!fragments.length || queries.length || mutations.length || subscriptions.length, 63);
  (0, _index.invariant)(queries.length + mutations.length + subscriptions.length <= 1, 64, document, queries.length, subscriptions.length, mutations.length);
  type = queries.length ? DocumentType.Query : DocumentType.Mutation;
  if (!queries.length && !mutations.length) type = DocumentType.Subscription;
  var definitions = queries.length ? queries : mutations.length ? mutations : subscriptions;
  (0, _index.invariant)(definitions.length === 1, 65, document, definitions.length);
  var definition = definitions[0];
  variables = definition.variableDefinitions || [];
  if (definition.name && definition.name.kind === "Name") {
    name = definition.name.value;
  } else {
    name = "data"; // fallback to using data if no name
  }
  var payload = {
    name: name,
    type: type,
    variables: variables
  };
  cache.set(document, payload);
  return payload;
}
parser.resetCache = function () {
  cache = undefined;
};
if (process.env.NODE_ENV !== "production") {
  (0, _getMemoryInternals.registerGlobalCache)("parser", function () {
    return cache ? cache.size : 0;
  });
}
function verifyDocumentType(document, type) {
  var operation = parser(document);
  var requiredOperationName = operationName(type);
  var usedOperationName = operationName(operation.type);
  (0, _index.invariant)(operation.type === type, 66, requiredOperationName, requiredOperationName, usedOperationName);
}