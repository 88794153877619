"use strict";
"use es6";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFragment = useFragment;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var React = _interopRequireWildcard(require("react"));
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _useApolloClient = require("apollo-stack-hubspot/@apollo/client/react/hooks/useApolloClient");
var _useSyncExternalStore = require("apollo-stack-hubspot/@apollo/client/react/hooks/useSyncExternalStore");
var _index2 = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/index");
var _index3 = _interopRequireDefault(require("apollo-stack-hubspot/internal/@wry/equality/lib/index"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function useFragment(options) {
  return (0, _index2.wrapHook)("useFragment", _useFragment, (0, _useApolloClient.useApolloClient)(options.client))(options);
}
function _useFragment(options) {
  var cache = (0, _useApolloClient.useApolloClient)(options.client).cache;
  var from = options.from,
    rest = (0, _tslib.__rest)(options, ["from"]);
  // We calculate the cache id seperately from `stableOptions` because we don't
  // want changes to non key fields in the `from` property to affect
  // `stableOptions` and retrigger our subscription. If the cache identifier
  // stays the same between renders, we want to reuse the existing subscription.
  var id = React.useMemo(function () {
    return typeof from === "string" ? from : cache.identify(from);
  }, [cache, from]);
  var stableOptions = (0, _index2.useDeepMemo)(function () {
    return (0, _tslib.__assign)((0, _tslib.__assign)({}, rest), {
      from: id
    });
  }, [rest, id]);
  // Since .next is async, we need to make sure that we
  // get the correct diff on the next render given new diffOptions
  var diff = React.useMemo(function () {
    var fragment = stableOptions.fragment,
      fragmentName = stableOptions.fragmentName,
      from = stableOptions.from,
      _a = stableOptions.optimistic,
      optimistic = _a === void 0 ? true : _a;
    return {
      result: diffToResult(cache.diff((0, _tslib.__assign)((0, _tslib.__assign)({}, stableOptions), {
        returnPartialData: true,
        id: from,
        query: cache["getFragmentDoc"](fragment, fragmentName),
        optimistic: optimistic
      })))
    };
  }, [stableOptions, cache]);
  // Used for both getSnapshot and getServerSnapshot
  var getSnapshot = React.useCallback(function () {
    return diff.result;
  }, [diff]);
  return (0, _useSyncExternalStore.useSyncExternalStore)(React.useCallback(function (forceUpdate) {
    var lastTimeout = 0;
    var subscription = cache.watchFragment(stableOptions).subscribe({
      next: function (result) {
        // Since `next` is called async by zen-observable, we want to avoid
        // unnecessarily rerendering this hook for the initial result
        // emitted from watchFragment which should be equal to
        // `diff.result`.
        if ((0, _index3.default)(result, diff.result)) return;
        diff.result = result;
        // If we get another update before we've re-rendered, bail out of
        // the update and try again. This ensures that the relative timing
        // between useQuery and useFragment stays roughly the same as
        // fixed in https://github.com/apollographql/apollo-client/pull/11083
        clearTimeout(lastTimeout);
        lastTimeout = setTimeout(forceUpdate);
      }
    });
    return function () {
      subscription.unsubscribe();
      clearTimeout(lastTimeout);
    };
  }, [cache, stableOptions, diff]), getSnapshot, getSnapshot);
}
function diffToResult(diff) {
  var result = {
    data: diff.result,
    complete: !!diff.complete
  };
  if (diff.missing) {
    result.missing = (0, _index.mergeDeepArray)(diff.missing.map(function (error) {
      return error.missing;
    }));
  }
  return result;
}