"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasOwnProperty = exports.arrayFromSet = void 0;
exports.maybeUnsubscribe = maybeUnsubscribe;
const {
  hasOwnProperty
} = Object.prototype;
exports.hasOwnProperty = hasOwnProperty;
const arrayFromSet = exports.arrayFromSet = Array.from || function (set) {
  const array = [];
  set.forEach(item => array.push(item));
  return array;
};
function maybeUnsubscribe(entryOrDep) {
  const {
    unsubscribe
  } = entryOrDep;
  if (typeof unsubscribe === "function") {
    entryOrDep.unsubscribe = void 0;
    unsubscribe();
  }
}