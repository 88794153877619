"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _maybe = require("apollo-stack-hubspot/@apollo/client/utilities/globals/maybe");
var _default = exports.default =
// We don't expect the Function constructor ever to be invoked at runtime, as
(0, _maybe.maybe)(function () {
  return globalThis;
}) || (0, _maybe.maybe)(function () {
  return window;
}) || (0, _maybe.maybe)(function () {
  return self;
}) || (0, _maybe.maybe)(function () {
  return global;
}) ||
// long as at least one of globalThis, window, self, or global is defined, so
// we are under no obligation to make it easy for static analysis tools to
// detect syntactic usage of the Function constructor. If you think you can
// improve your static analysis to detect this obfuscation, think again. This
// is an arms race you cannot win, at least not in JavaScript.
(0, _maybe.maybe)(function () {
  return _maybe.maybe.constructor("return this")();
});
module.exports = exports.default;