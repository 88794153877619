"use strict";
"use es6";

/**
 * Original source:
 * https://github.com/kmalakoff/response-iterator/blob/master/src/iterators/async.ts
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = asyncIterator;
function asyncIterator(source) {
  var _a;
  var iterator = source[Symbol.asyncIterator]();
  return _a = {
    next: function () {
      return iterator.next();
    }
  }, _a[Symbol.asyncIterator] = function () {
    return this;
  }, _a;
}
module.exports = exports.default;