"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Slot", {
  enumerable: true,
  get: function () {
    return _index.Slot;
  }
});
Object.defineProperty(exports, "asyncFromGen", {
  enumerable: true,
  get: function () {
    return _index.asyncFromGen;
  }
});
Object.defineProperty(exports, "bindContext", {
  enumerable: true,
  get: function () {
    return _index.bind;
  }
});
Object.defineProperty(exports, "noContext", {
  enumerable: true,
  get: function () {
    return _index.noContext;
  }
});
exports.nonReactive = nonReactive;
exports.parentEntrySlot = void 0;
Object.defineProperty(exports, "setTimeout", {
  enumerable: true,
  get: function () {
    return _index.setTimeout;
  }
});
var _index = require("apollo-stack-hubspot/internal/@wry/context/lib/index");
const parentEntrySlot = exports.parentEntrySlot = new _index.Slot();
function nonReactive(fn) {
  return parentEntrySlot.withValue(void 0, fn);
}