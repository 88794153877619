"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  useQuery: true,
  useSuspenseQuery: true,
  useBackgroundQuery: true,
  useLoadableQuery: true,
  useQueryRefHandlers: true,
  useReadQuery: true,
  skipToken: true
};
Object.defineProperty(exports, "skipToken", {
  enumerable: true,
  get: function () {
    return _constants.skipToken;
  }
});
Object.defineProperty(exports, "useBackgroundQuery", {
  enumerable: true,
  get: function () {
    return _useBackgroundQuery.useBackgroundQuery;
  }
});
Object.defineProperty(exports, "useLoadableQuery", {
  enumerable: true,
  get: function () {
    return _useLoadableQuery.useLoadableQuery;
  }
});
Object.defineProperty(exports, "useQuery", {
  enumerable: true,
  get: function () {
    return _useQuery.useQuery;
  }
});
Object.defineProperty(exports, "useQueryRefHandlers", {
  enumerable: true,
  get: function () {
    return _useQueryRefHandlers.useQueryRefHandlers;
  }
});
Object.defineProperty(exports, "useReadQuery", {
  enumerable: true,
  get: function () {
    return _useReadQuery.useReadQuery;
  }
});
Object.defineProperty(exports, "useSuspenseQuery", {
  enumerable: true,
  get: function () {
    return _useSuspenseQuery.useSuspenseQuery;
  }
});
require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _useApolloClient = require("apollo-stack-hubspot/@apollo/client/react/hooks/useApolloClient");
Object.keys(_useApolloClient).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useApolloClient[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useApolloClient[key];
    }
  });
});
var _useLazyQuery = require("apollo-stack-hubspot/@apollo/client/react/hooks/useLazyQuery");
Object.keys(_useLazyQuery).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useLazyQuery[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useLazyQuery[key];
    }
  });
});
var _useMutation = require("apollo-stack-hubspot/@apollo/client/react/hooks/useMutation");
Object.keys(_useMutation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useMutation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useMutation[key];
    }
  });
});
var _useQuery = require("apollo-stack-hubspot/@apollo/client/react/hooks/useQuery");
var _useSubscription = require("apollo-stack-hubspot/@apollo/client/react/hooks/useSubscription");
Object.keys(_useSubscription).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useSubscription[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useSubscription[key];
    }
  });
});
var _useReactiveVar = require("apollo-stack-hubspot/@apollo/client/react/hooks/useReactiveVar");
Object.keys(_useReactiveVar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useReactiveVar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useReactiveVar[key];
    }
  });
});
var _useFragment = require("apollo-stack-hubspot/@apollo/client/react/hooks/useFragment");
Object.keys(_useFragment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useFragment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useFragment[key];
    }
  });
});
var _useSuspenseQuery = require("apollo-stack-hubspot/@apollo/client/react/hooks/useSuspenseQuery");
var _useBackgroundQuery = require("apollo-stack-hubspot/@apollo/client/react/hooks/useBackgroundQuery");
var _useLoadableQuery = require("apollo-stack-hubspot/@apollo/client/react/hooks/useLoadableQuery");
var _useQueryRefHandlers = require("apollo-stack-hubspot/@apollo/client/react/hooks/useQueryRefHandlers");
var _useReadQuery = require("apollo-stack-hubspot/@apollo/client/react/hooks/useReadQuery");
var _constants = require("apollo-stack-hubspot/@apollo/client/react/hooks/constants");