"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOperation = createOperation;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
function createOperation(starting, operation) {
  var context = (0, _tslib.__assign)({}, starting);
  var setContext = function setContext(next) {
    if (typeof next === "function") {
      context = (0, _tslib.__assign)((0, _tslib.__assign)({}, context), next(context));
    } else {
      context = (0, _tslib.__assign)((0, _tslib.__assign)({}, context), next);
    }
  };
  var getContext = function getContext() {
    return (0, _tslib.__assign)({}, context);
  };
  Object.defineProperty(operation, "setContext", {
    enumerable: false,
    value: setContext
  });
  Object.defineProperty(operation, "getContext", {
    enumerable: false,
    value: getContext
  });
  return operation;
}