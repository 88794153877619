"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLoadableQuery = useLoadableQuery;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var React = _interopRequireWildcard(require("react"));
var _useApolloClient = require("apollo-stack-hubspot/@apollo/client/react/hooks/useApolloClient");
var _index = require("apollo-stack-hubspot/@apollo/client/react/internal/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/index");
var _useSuspenseQuery = require("apollo-stack-hubspot/@apollo/client/react/hooks/useSuspenseQuery");
var _index3 = require("apollo-stack-hubspot/@apollo/client/cache/index");
var _index4 = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function useLoadableQuery(query, options) {
  if (options === void 0) {
    options = Object.create(null);
  }
  var client = (0, _useApolloClient.useApolloClient)(options.client);
  var suspenseCache = (0, _index.getSuspenseCache)(client);
  var watchQueryOptions = (0, _useSuspenseQuery.useWatchQueryOptions)({
    client: client,
    query: query,
    options: options
  });
  var _a = options.queryKey,
    queryKey = _a === void 0 ? [] : _a;
  var _b = React.useState(null),
    queryRef = _b[0],
    setQueryRef = _b[1];
  (0, _index.assertWrappedQueryRef)(queryRef);
  var internalQueryRef = queryRef && (0, _index.unwrapQueryRef)(queryRef);
  if (queryRef && (internalQueryRef === null || internalQueryRef === void 0 ? void 0 : internalQueryRef.didChangeOptions(watchQueryOptions))) {
    var promise = internalQueryRef.applyOptions(watchQueryOptions);
    (0, _index.updateWrappedQueryRef)(queryRef, promise);
  }
  var calledDuringRender = (0, _index2.useRenderGuard)();
  var fetchMore = React.useCallback(function (options) {
    if (!internalQueryRef) {
      throw new Error("The query has not been loaded. Please load the query.");
    }
    var promise = internalQueryRef.fetchMore(options);
    setQueryRef((0, _index.wrapQueryRef)(internalQueryRef));
    return promise;
  }, [internalQueryRef]);
  var refetch = React.useCallback(function (options) {
    if (!internalQueryRef) {
      throw new Error("The query has not been loaded. Please load the query.");
    }
    var promise = internalQueryRef.refetch(options);
    setQueryRef((0, _index.wrapQueryRef)(internalQueryRef));
    return promise;
  }, [internalQueryRef]);
  var loadQuery = React.useCallback(function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    (0, _index4.invariant)(!calledDuringRender(), 51);
    var variables = args[0];
    var cacheKey = (0, _tslib.__spreadArray)([query, (0, _index3.canonicalStringify)(variables)], [].concat(queryKey), true);
    var queryRef = suspenseCache.getQueryRef(cacheKey, function () {
      return client.watchQuery((0, _tslib.__assign)((0, _tslib.__assign)({}, watchQueryOptions), {
        variables: variables
      }));
    });
    setQueryRef((0, _index.wrapQueryRef)(queryRef));
  }, [query, queryKey, suspenseCache, watchQueryOptions, calledDuringRender, client]);
  var subscribeToMore = React.useCallback(function (options) {
    (0, _index4.invariant)(internalQueryRef, 52);
    return internalQueryRef.observable.subscribeToMore(options);
  }, [internalQueryRef]);
  var reset = React.useCallback(function () {
    setQueryRef(null);
  }, []);
  return [loadQuery, queryRef, {
    fetchMore: fetchMore,
    refetch: refetch,
    reset: reset,
    subscribeToMore: subscribeToMore
  }];
}