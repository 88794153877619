"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useQueryRefHandlers = useQueryRefHandlers;
var React = _interopRequireWildcard(require("react"));
var _index = require("apollo-stack-hubspot/@apollo/client/react/internal/index");
var _useApolloClient = require("apollo-stack-hubspot/@apollo/client/react/hooks/useApolloClient");
var _index2 = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/index");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
/**
 * A React hook that returns a `refetch` and `fetchMore` function for a given
 * `queryRef`.
 *
 * This is useful to get access to handlers for a `queryRef` that was created by
 * `createQueryPreloader` or when the handlers for a `queryRef` produced in
 * a different component are inaccessible.
 *
 * @example
 * ```tsx
 * const MyComponent({ queryRef }) {
 *   const { refetch, fetchMore } = useQueryRefHandlers(queryRef);
 *
 *   // ...
 * }
 * ```
 * @since 3.9.0
 * @param queryRef - A `QueryRef` returned from `useBackgroundQuery`, `useLoadableQuery`, or `createQueryPreloader`.
 */
function useQueryRefHandlers(queryRef) {
  var unwrapped = (0, _index.unwrapQueryRef)(queryRef);
  return (0, _index2.wrapHook)("useQueryRefHandlers", _useQueryRefHandlers, unwrapped ? unwrapped["observable"]
  // in the case of a "transported" queryRef object, we need to use the
  // client that's available to us at the current position in the React tree
  // that ApolloClient will then have the job to recreate a real queryRef from
  // the transported object
  // This is just a context read - it's fine to do this conditionally.
  // This hook wrapper also shouldn't be optimized by React Compiler.
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/rules-of-hooks
  : (0, _useApolloClient.useApolloClient)())(queryRef);
}
function _useQueryRefHandlers(queryRef) {
  (0, _index.assertWrappedQueryRef)(queryRef);
  var _a = React.useState(queryRef),
    previousQueryRef = _a[0],
    setPreviousQueryRef = _a[1];
  var _b = React.useState(queryRef),
    wrappedQueryRef = _b[0],
    setWrappedQueryRef = _b[1];
  var internalQueryRef = (0, _index.unwrapQueryRef)(queryRef);
  // To ensure we can support React transitions, this hook needs to manage the
  // queryRef state and apply React's state value immediately to the existing
  // queryRef since this hook doesn't return the queryRef directly
  if (previousQueryRef !== queryRef) {
    setPreviousQueryRef(queryRef);
    setWrappedQueryRef(queryRef);
  } else {
    (0, _index.updateWrappedQueryRef)(queryRef, (0, _index.getWrappedPromise)(wrappedQueryRef));
  }
  var refetch = React.useCallback(function (variables) {
    var promise = internalQueryRef.refetch(variables);
    setWrappedQueryRef((0, _index.wrapQueryRef)(internalQueryRef));
    return promise;
  }, [internalQueryRef]);
  var fetchMore = React.useCallback(function (options) {
    var promise = internalQueryRef.fetchMore(options);
    setWrappedQueryRef((0, _index.wrapQueryRef)(internalQueryRef));
    return promise;
  }, [internalQueryRef]);
  return {
    refetch: refetch,
    fetchMore: fetchMore,
    subscribeToMore: internalQueryRef.observable.subscribeToMore
  };
}