"use strict";
"use es6";

/**
 * Original source:
 * https://github.com/kmalakoff/response-iterator/blob/master/src/index.ts
 */
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.responseIterator = responseIterator;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _async = _interopRequireDefault(require("apollo-stack-hubspot/@apollo/client/link/http/iterators/async"));
var _nodeStream = _interopRequireDefault(require("apollo-stack-hubspot/@apollo/client/link/http/iterators/nodeStream"));
var _promise = _interopRequireDefault(require("apollo-stack-hubspot/@apollo/client/link/http/iterators/promise"));
var _reader = _interopRequireDefault(require("apollo-stack-hubspot/@apollo/client/link/http/iterators/reader"));
function isNodeResponse(value) {
  return !!value.body;
}
function isReadableStream(value) {
  return !!value.getReader;
}
function isAsyncIterableIterator(value) {
  return !!(_index.canUseAsyncIteratorSymbol && value[Symbol.asyncIterator]);
}
function isStreamableBlob(value) {
  return !!value.stream;
}
function isBlob(value) {
  return !!value.arrayBuffer;
}
function isNodeReadableStream(value) {
  return !!value.pipe;
}
function responseIterator(response) {
  var body = response;
  if (isNodeResponse(response)) body = response.body;
  if (isAsyncIterableIterator(body)) return (0, _async.default)(body);
  if (isReadableStream(body)) return (0, _reader.default)(body.getReader());
  // this errors without casting to ReadableStream<T>
  // because Blob.stream() returns a NodeJS ReadableStream
  if (isStreamableBlob(body)) {
    return (0, _reader.default)(body.stream().getReader());
  }
  if (isBlob(body)) return (0, _promise.default)(body.arrayBuffer());
  if (isNodeReadableStream(body)) return (0, _nodeStream.default)(body);
  throw new Error("Unknown body type for responseIterator. Please pass a streamable response.");
}