"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.throwServerError = void 0;
var throwServerError = exports.throwServerError = function throwServerError(response, result, message) {
  var error = new Error(message);
  error.name = "ServerError";
  error.response = response;
  error.statusCode = response.status;
  error.result = result;
  throw error;
};