"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "InternalQueryReference", {
  enumerable: true,
  get: function () {
    return _QueryReference.InternalQueryReference;
  }
});
Object.defineProperty(exports, "assertWrappedQueryRef", {
  enumerable: true,
  get: function () {
    return _QueryReference.assertWrappedQueryRef;
  }
});
Object.defineProperty(exports, "getSuspenseCache", {
  enumerable: true,
  get: function () {
    return _getSuspenseCache.getSuspenseCache;
  }
});
Object.defineProperty(exports, "getWrappedPromise", {
  enumerable: true,
  get: function () {
    return _QueryReference.getWrappedPromise;
  }
});
Object.defineProperty(exports, "unwrapQueryRef", {
  enumerable: true,
  get: function () {
    return _QueryReference.unwrapQueryRef;
  }
});
Object.defineProperty(exports, "updateWrappedQueryRef", {
  enumerable: true,
  get: function () {
    return _QueryReference.updateWrappedQueryRef;
  }
});
Object.defineProperty(exports, "wrapQueryRef", {
  enumerable: true,
  get: function () {
    return _QueryReference.wrapQueryRef;
  }
});
var _getSuspenseCache = require("apollo-stack-hubspot/@apollo/client/react/internal/cache/getSuspenseCache");
var _QueryReference = require("apollo-stack-hubspot/@apollo/client/react/internal/cache/QueryReference");