"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSuspenseCache = getSuspenseCache;
var _SuspenseCache = require("apollo-stack-hubspot/@apollo/client/react/internal/cache/SuspenseCache");
var suspenseCacheSymbol = Symbol.for("apollo.suspenseCache");
function getSuspenseCache(client) {
  var _a;
  if (!client[suspenseCacheSymbol]) {
    client[suspenseCacheSymbol] = new _SuspenseCache.SuspenseCache((_a = client.defaultOptions.react) === null || _a === void 0 ? void 0 : _a.suspense);
  }
  return client[suspenseCacheSymbol];
}