"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createQueryPreloader = createQueryPreloader;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var _index = require("apollo-stack-hubspot/@apollo/client/react/internal/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/index");
/**
 * A higher order function that returns a `preloadQuery` function which
 * can be used to begin loading a query with the given `client`. This is useful
 * when you want to start loading a query as early as possible outside of a
 * React component.
 *
 * > Refer to the [Suspense - Initiating queries outside React](https://www.apollographql.com/docs/react/data/suspense#initiating-queries-outside-react) section for a more in-depth overview.
 *
 * @param client - The `ApolloClient` instance that will be used to load queries
 * from the returned `preloadQuery` function.
 * @returns The `preloadQuery` function.
 *
 * @example
 * ```js
 * const preloadQuery = createQueryPreloader(client);
 * ```
 * @since 3.9.0
 */
function createQueryPreloader(client) {
  return (0, _index2.wrapHook)("createQueryPreloader", _createQueryPreloader, client)(client);
}
var _createQueryPreloader = function _createQueryPreloader(client) {
  return function preloadQuery(query, options) {
    var _a, _b;
    if (options === void 0) {
      options = Object.create(null);
    }
    var queryRef = new _index.InternalQueryReference(client.watchQuery((0, _tslib.__assign)((0, _tslib.__assign)({}, options), {
      query: query
    })), {
      autoDisposeTimeoutMs: (_b = (_a = client.defaultOptions.react) === null || _a === void 0 ? void 0 : _a.suspense) === null || _b === void 0 ? void 0 : _b.autoDisposeTimeoutMs
    });
    return (0, _index.wrapQueryRef)(queryRef);
  };
};