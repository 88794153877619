"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "StrongCache", {
  enumerable: true,
  get: function () {
    return _strong.StrongCache;
  }
});
Object.defineProperty(exports, "WeakCache", {
  enumerable: true,
  get: function () {
    return _weak.WeakCache;
  }
});
var _strong = require("apollo-stack-hubspot/internal/@wry/caches/lib/strong");
var _weak = require("apollo-stack-hubspot/internal/@wry/caches/lib/weak");