"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromError = fromError;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/index");
function fromError(errorValue) {
  return new _index.Observable(function (observer) {
    observer.error(errorValue);
  });
}