"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpLink = void 0;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var _index = require("apollo-stack-hubspot/@apollo/client/link/core/index");
var _createHttpLink = require("apollo-stack-hubspot/@apollo/client/link/http/createHttpLink");
var HttpLink = exports.HttpLink = /** @class */function (_super) {
  (0, _tslib.__extends)(HttpLink, _super);
  function HttpLink(options) {
    if (options === void 0) {
      options = {};
    }
    var _this = _super.call(this, (0, _createHttpLink.createHttpLink)(options).request) || this;
    _this.options = options;
    return _this;
  }
  return HttpLink;
}(_index.ApolloLink);