"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dep = dep;
var _context = require("apollo-stack-hubspot/internal/optimism/lib/context");
var _helpers = require("apollo-stack-hubspot/internal/optimism/lib/helpers");
const EntryMethods = {
  setDirty: true,
  dispose: true,
  forget: true // Fully remove parent Entry from LRU cache and computation graph
};
function dep(options) {
  const depsByKey = new Map();
  const subscribe = options && options.subscribe;
  function depend(key) {
    const parent = _context.parentEntrySlot.getValue();
    if (parent) {
      let dep = depsByKey.get(key);
      if (!dep) {
        depsByKey.set(key, dep = new Set());
      }
      parent.dependOn(dep);
      if (typeof subscribe === "function") {
        (0, _helpers.maybeUnsubscribe)(dep);
        dep.unsubscribe = subscribe(key);
      }
    }
  }
  depend.dirty = function dirty(key, entryMethodName) {
    const dep = depsByKey.get(key);
    if (dep) {
      const m = entryMethodName && _helpers.hasOwnProperty.call(EntryMethods, entryMethodName) ? entryMethodName : "setDirty";
      // We have to use arrayFromSet(dep).forEach instead of dep.forEach,
      // because modifying a Set while iterating over it can cause elements in
      // the Set to be removed from the Set before they've been iterated over.
      (0, _helpers.arrayFromSet)(dep).forEach(entry => entry[m]());
      depsByKey.delete(key);
      (0, _helpers.maybeUnsubscribe)(dep);
    }
  };
  return depend;
}