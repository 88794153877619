"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SuspenseCache = void 0;
var _index = require("apollo-stack-hubspot/internal/@wry/trie/lib/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _QueryReference = require("apollo-stack-hubspot/@apollo/client/react/internal/cache/QueryReference");
var SuspenseCache = exports.SuspenseCache = /** @class */function () {
  function SuspenseCache(options) {
    if (options === void 0) {
      options = Object.create(null);
    }
    this.queryRefs = new _index.Trie(_index2.canUseWeakMap);
    this.options = options;
  }
  SuspenseCache.prototype.getQueryRef = function (cacheKey, createObservable) {
    var ref = this.queryRefs.lookupArray(cacheKey);
    if (!ref.current) {
      ref.current = new _QueryReference.InternalQueryReference(createObservable(), {
        autoDisposeTimeoutMs: this.options.autoDisposeTimeoutMs,
        onDispose: function () {
          delete ref.current;
        }
      });
    }
    return ref.current;
  };
  SuspenseCache.prototype.add = function (cacheKey, queryRef) {
    var ref = this.queryRefs.lookupArray(cacheKey);
    ref.current = queryRef;
  };
  return SuspenseCache;
}();