"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoreReader = void 0;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _kinds = require("graphql/language/kinds");
var _index2 = require("apollo-stack-hubspot/internal/optimism/lib/index");
var _index3 = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _entityStore = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/entityStore");
var _helpers = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/helpers");
var _common = require("apollo-stack-hubspot/@apollo/client/cache/core/types/common");
var _objectCanon = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/object-canon");
function execSelectionSetKeyArgs(options) {
  return [options.selectionSet, options.objectOrReference, options.context,
  // We split out this property so we can pass different values
  // independently without modifying options.context itself.
  options.context.canonizeResults];
}
var StoreReader = exports.StoreReader = /** @class */function () {
  function StoreReader(config) {
    var _this = this;
    this.knownResults = new (_index3.canUseWeakMap ? WeakMap : Map)();
    this.config = (0, _index3.compact)(config, {
      addTypename: config.addTypename !== false,
      canonizeResults: (0, _helpers.shouldCanonizeResults)(config)
    });
    this.canon = config.canon || new _objectCanon.ObjectCanon();
    // memoized functions in this class will be "garbage-collected"
    // by recreating the whole `StoreReader` in
    // `InMemoryCache.resetResultsCache`
    // (triggered from `InMemoryCache.gc` with `resetResultCache: true`)
    this.executeSelectionSet = (0, _index2.wrap)(function (options) {
      var _a;
      var canonizeResults = options.context.canonizeResults;
      var peekArgs = execSelectionSetKeyArgs(options);
      // Negate this boolean option so we can find out if we've already read
      // this result using the other boolean value.
      peekArgs[3] = !canonizeResults;
      var other = (_a = _this.executeSelectionSet).peek.apply(_a, peekArgs);
      if (other) {
        if (canonizeResults) {
          return (0, _tslib.__assign)((0, _tslib.__assign)({}, other), {
            // If we previously read this result without canonizing it, we can
            // reuse that result simply by canonizing it now.
            result: _this.canon.admit(other.result)
          });
        }
        // If we previously read this result with canonization enabled, we can
        // return that canonized result as-is.
        return other;
      }
      (0, _entityStore.maybeDependOnExistenceOfEntity)(options.context.store, options.enclosingRef.__ref);
      // Finally, if we didn't find any useful previous results, run the real
      // execSelectionSetImpl method with the given options.
      return _this.execSelectionSetImpl(options);
    }, {
      max: this.config.resultCacheMaxSize || _index3.cacheSizes["inMemoryCache.executeSelectionSet"] || 50000 /* defaultCacheSizes["inMemoryCache.executeSelectionSet"] */,
      keyArgs: execSelectionSetKeyArgs,
      // Note that the parameters of makeCacheKey are determined by the
      // array returned by keyArgs.
      makeCacheKey: function (selectionSet, parent, context, canonizeResults) {
        if ((0, _entityStore.supportsResultCaching)(context.store)) {
          return context.store.makeCacheKey(selectionSet, (0, _index3.isReference)(parent) ? parent.__ref : parent, context.varString, canonizeResults);
        }
      }
    });
    this.executeSubSelectedArray = (0, _index2.wrap)(function (options) {
      (0, _entityStore.maybeDependOnExistenceOfEntity)(options.context.store, options.enclosingRef.__ref);
      return _this.execSubSelectedArrayImpl(options);
    }, {
      max: this.config.resultCacheMaxSize || _index3.cacheSizes["inMemoryCache.executeSubSelectedArray"] || 10000 /* defaultCacheSizes["inMemoryCache.executeSubSelectedArray"] */,
      makeCacheKey: function (_a) {
        var field = _a.field,
          array = _a.array,
          context = _a.context;
        if ((0, _entityStore.supportsResultCaching)(context.store)) {
          return context.store.makeCacheKey(field, array, context.varString);
        }
      }
    });
  }
  StoreReader.prototype.resetCanon = function () {
    this.canon = new _objectCanon.ObjectCanon();
  };
  /**
   * Given a store and a query, return as much of the result as possible and
   * identify if any data was missing from the store.
   */
  StoreReader.prototype.diffQueryAgainstStore = function (_a) {
    var store = _a.store,
      query = _a.query,
      _b = _a.rootId,
      rootId = _b === void 0 ? "ROOT_QUERY" : _b,
      variables = _a.variables,
      _c = _a.returnPartialData,
      returnPartialData = _c === void 0 ? true : _c,
      _d = _a.canonizeResults,
      canonizeResults = _d === void 0 ? this.config.canonizeResults : _d;
    var policies = this.config.cache.policies;
    variables = (0, _tslib.__assign)((0, _tslib.__assign)({}, (0, _index3.getDefaultValues)((0, _index3.getQueryDefinition)(query))), variables);
    var rootRef = (0, _index3.makeReference)(rootId);
    var execResult = this.executeSelectionSet({
      selectionSet: (0, _index3.getMainDefinition)(query).selectionSet,
      objectOrReference: rootRef,
      enclosingRef: rootRef,
      context: (0, _tslib.__assign)({
        store: store,
        query: query,
        policies: policies,
        variables: variables,
        varString: (0, _index3.canonicalStringify)(variables),
        canonizeResults: canonizeResults
      }, (0, _helpers.extractFragmentContext)(query, this.config.fragments))
    });
    var missing;
    if (execResult.missing) {
      // For backwards compatibility we still report an array of
      // MissingFieldError objects, even though there will only ever be at most
      // one of them, now that all missing field error messages are grouped
      // together in the execResult.missing tree.
      missing = [new _common.MissingFieldError(firstMissing(execResult.missing), execResult.missing, query, variables)];
      if (!returnPartialData) {
        throw missing[0];
      }
    }
    return {
      result: execResult.result,
      complete: !missing,
      missing: missing
    };
  };
  StoreReader.prototype.isFresh = function (result, parent, selectionSet, context) {
    if ((0, _entityStore.supportsResultCaching)(context.store) && this.knownResults.get(result) === selectionSet) {
      var latest = this.executeSelectionSet.peek(selectionSet, parent, context,
      // If result is canonical, then it could only have been previously
      // cached by the canonizing version of executeSelectionSet, so we can
      // avoid checking both possibilities here.
      this.canon.isKnown(result));
      if (latest && result === latest.result) {
        return true;
      }
    }
    return false;
  };
  // Uncached version of executeSelectionSet.
  StoreReader.prototype.execSelectionSetImpl = function (_a) {
    var _this = this;
    var selectionSet = _a.selectionSet,
      objectOrReference = _a.objectOrReference,
      enclosingRef = _a.enclosingRef,
      context = _a.context;
    if ((0, _index3.isReference)(objectOrReference) && !context.policies.rootTypenamesById[objectOrReference.__ref] && !context.store.has(objectOrReference.__ref)) {
      return {
        result: this.canon.empty,
        missing: "Dangling reference to missing ".concat(objectOrReference.__ref, " object")
      };
    }
    var variables = context.variables,
      policies = context.policies,
      store = context.store;
    var typename = store.getFieldValue(objectOrReference, "__typename");
    var objectsToMerge = [];
    var missing;
    var missingMerger = new _index3.DeepMerger();
    if (this.config.addTypename && typeof typename === "string" && !policies.rootIdsByTypename[typename]) {
      // Ensure we always include a default value for the __typename
      // field, if we have one, and this.config.addTypename is true. Note
      // that this field can be overridden by other merged objects.
      objectsToMerge.push({
        __typename: typename
      });
    }
    function handleMissing(result, resultName) {
      var _a;
      if (result.missing) {
        missing = missingMerger.merge(missing, (_a = {}, _a[resultName] = result.missing, _a));
      }
      return result.result;
    }
    var workSet = new Set(selectionSet.selections);
    workSet.forEach(function (selection) {
      var _a, _b;
      // Omit fields with directives @skip(if: <truthy value>) or
      // @include(if: <falsy value>).
      if (!(0, _index3.shouldInclude)(selection, variables)) return;
      if ((0, _index3.isField)(selection)) {
        var fieldValue = policies.readField({
          fieldName: selection.name.value,
          field: selection,
          variables: context.variables,
          from: objectOrReference
        }, context);
        var resultName = (0, _index3.resultKeyNameFromField)(selection);
        if (fieldValue === void 0) {
          if (!_index3.addTypenameToDocument.added(selection)) {
            missing = missingMerger.merge(missing, (_a = {}, _a[resultName] = "Can't find field '".concat(selection.name.value, "' on ").concat((0, _index3.isReference)(objectOrReference) ? objectOrReference.__ref + " object" : "object " + JSON.stringify(objectOrReference, null, 2)), _a));
          }
        } else if ((0, _helpers.isArray)(fieldValue)) {
          if (fieldValue.length > 0) {
            fieldValue = handleMissing(_this.executeSubSelectedArray({
              field: selection,
              array: fieldValue,
              enclosingRef: enclosingRef,
              context: context
            }), resultName);
          }
        } else if (!selection.selectionSet) {
          // If the field does not have a selection set, then we handle it
          // as a scalar value. To keep this.canon from canonicalizing
          // this value, we use this.canon.pass to wrap fieldValue in a
          // Pass object that this.canon.admit will later unwrap as-is.
          if (context.canonizeResults) {
            fieldValue = _this.canon.pass(fieldValue);
          }
        } else if (fieldValue != null) {
          // In this case, because we know the field has a selection set,
          // it must be trying to query a GraphQLObjectType, which is why
          // fieldValue must be != null.
          fieldValue = handleMissing(_this.executeSelectionSet({
            selectionSet: selection.selectionSet,
            objectOrReference: fieldValue,
            enclosingRef: (0, _index3.isReference)(fieldValue) ? fieldValue : enclosingRef,
            context: context
          }), resultName);
        }
        if (fieldValue !== void 0) {
          objectsToMerge.push((_b = {}, _b[resultName] = fieldValue, _b));
        }
      } else {
        var fragment = (0, _index3.getFragmentFromSelection)(selection, context.lookupFragment);
        if (!fragment && selection.kind === _kinds.Kind.FRAGMENT_SPREAD) {
          throw (0, _index.newInvariantError)(9, selection.name.value);
        }
        if (fragment && policies.fragmentMatches(fragment, typename)) {
          fragment.selectionSet.selections.forEach(workSet.add, workSet);
        }
      }
    });
    var result = (0, _index3.mergeDeepArray)(objectsToMerge);
    var finalResult = {
      result: result,
      missing: missing
    };
    var frozen = context.canonizeResults ? this.canon.admit(finalResult)
    // Since this.canon is normally responsible for freezing results (only in
    // development), freeze them manually if canonization is disabled.
    : (0, _index3.maybeDeepFreeze)(finalResult);
    // Store this result with its selection set so that we can quickly
    // recognize it again in the StoreReader#isFresh method.
    if (frozen.result) {
      this.knownResults.set(frozen.result, selectionSet);
    }
    return frozen;
  };
  // Uncached version of executeSubSelectedArray.
  StoreReader.prototype.execSubSelectedArrayImpl = function (_a) {
    var _this = this;
    var field = _a.field,
      array = _a.array,
      enclosingRef = _a.enclosingRef,
      context = _a.context;
    var missing;
    var missingMerger = new _index3.DeepMerger();
    function handleMissing(childResult, i) {
      var _a;
      if (childResult.missing) {
        missing = missingMerger.merge(missing, (_a = {}, _a[i] = childResult.missing, _a));
      }
      return childResult.result;
    }
    if (field.selectionSet) {
      array = array.filter(context.store.canRead);
    }
    array = array.map(function (item, i) {
      // null value in array
      if (item === null) {
        return null;
      }
      // This is a nested array, recurse
      if ((0, _helpers.isArray)(item)) {
        return handleMissing(_this.executeSubSelectedArray({
          field: field,
          array: item,
          enclosingRef: enclosingRef,
          context: context
        }), i);
      }
      // This is an object, run the selection set on it
      if (field.selectionSet) {
        return handleMissing(_this.executeSelectionSet({
          selectionSet: field.selectionSet,
          objectOrReference: item,
          enclosingRef: (0, _index3.isReference)(item) ? item : enclosingRef,
          context: context
        }), i);
      }
      if (process.env.NODE_ENV !== "production") {
        assertSelectionSetForIdValue(context.store, field, item);
      }
      return item;
    });
    return {
      result: context.canonizeResults ? this.canon.admit(array) : array,
      missing: missing
    };
  };
  return StoreReader;
}();
function firstMissing(tree) {
  try {
    JSON.stringify(tree, function (_, value) {
      if (typeof value === "string") throw value;
      return value;
    });
  } catch (result) {
    return result;
  }
}
function assertSelectionSetForIdValue(store, field, fieldValue) {
  if (!field.selectionSet) {
    var workSet_1 = new Set([fieldValue]);
    workSet_1.forEach(function (value) {
      if ((0, _index3.isNonNullObject)(value)) {
        (0, _index.invariant)(!(0, _index3.isReference)(value), 10, (0, _helpers.getTypenameFromStoreObject)(store, value), field.name.value);
        Object.values(value).forEach(workSet_1.add, workSet_1);
      }
    });
  }
}