"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mergeOptions = mergeOptions;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var _compact = require("apollo-stack-hubspot/@apollo/client/utilities/common/compact");
function mergeOptions(defaults, options) {
  return (0, _compact.compact)(defaults, options, options.variables && {
    variables: (0, _tslib.__assign)((0, _tslib.__assign)({}, defaults && defaults.variables), options.variables)
  });
}