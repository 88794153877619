"use strict";
"use es6";

/**
 * Original source:
 * https://github.com/kmalakoff/response-iterator/blob/master/src/iterators/promise.ts
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = promiseIterator;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/index");
function promiseIterator(promise) {
  var resolved = false;
  var iterator = {
    next: function () {
      if (resolved) return Promise.resolve({
        value: undefined,
        done: true
      });
      resolved = true;
      return new Promise(function (resolve, reject) {
        promise.then(function (value) {
          resolve({
            value: value,
            done: false
          });
        }).catch(reject);
      });
    }
  };
  if (_index.canUseAsyncIteratorSymbol) {
    iterator[Symbol.asyncIterator] = function () {
      return this;
    };
  }
  return iterator;
}
module.exports = exports.default;