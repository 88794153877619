"use strict";
"use es6";

/**
 * Original source:
 * https://github.com/kmalakoff/response-iterator/blob/master/src/iterators/reader.ts
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = readerIterator;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/index");
function readerIterator(reader) {
  var iterator = {
    next: function () {
      return reader.read();
    }
  };
  if (_index.canUseAsyncIteratorSymbol) {
    iterator[Symbol.asyncIterator] = function () {
      return this;
    };
  }
  return iterator;
}
module.exports = exports.default;