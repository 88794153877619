"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PROTOCOL_ERRORS_SYMBOL = exports.ApolloError = void 0;
exports.graphQLResultHasProtocolErrors = graphQLResultHasProtocolErrors;
exports.isApolloError = isApolloError;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/utilities/index");
// This Symbol allows us to pass transport-specific errors from the link chain
// into QueryManager/client internals without risking a naming collision within
// extensions (which implementers can use as they see fit).
var PROTOCOL_ERRORS_SYMBOL = exports.PROTOCOL_ERRORS_SYMBOL = Symbol();
function graphQLResultHasProtocolErrors(result) {
  if (result.extensions) {
    return Array.isArray(result.extensions[PROTOCOL_ERRORS_SYMBOL]);
  }
  return false;
}
function isApolloError(err) {
  return err.hasOwnProperty("graphQLErrors");
}
// Sets the error message on this error according to the
// the GraphQL and network errors that are present.
// If the error message has already been set through the
// constructor or otherwise, this function is a nop.
var generateErrorMessage = function generateErrorMessage(err) {
  var errors = (0, _tslib.__spreadArray)((0, _tslib.__spreadArray)((0, _tslib.__spreadArray)([], err.graphQLErrors, true), err.clientErrors, true), err.protocolErrors, true);
  if (err.networkError) errors.push(err.networkError);
  return errors
  // The rest of the code sometimes unsafely types non-Error objects as GraphQLErrors
  .map(function (err) {
    return (0, _index2.isNonNullObject)(err) && err.message || "Error message not found.";
  }).join("\n");
};
var ApolloError = exports.ApolloError = /** @class */function (_super) {
  (0, _tslib.__extends)(ApolloError, _super);
  // Constructs an instance of ApolloError given serialized GraphQL errors,
  // client errors, protocol errors or network errors.
  // Note that one of these has to be a valid
  // value or the constructed error will be meaningless.
  function ApolloError(_a) {
    var graphQLErrors = _a.graphQLErrors,
      protocolErrors = _a.protocolErrors,
      clientErrors = _a.clientErrors,
      networkError = _a.networkError,
      errorMessage = _a.errorMessage,
      extraInfo = _a.extraInfo;
    var _this = _super.call(this, errorMessage) || this;
    _this.name = "ApolloError";
    _this.graphQLErrors = graphQLErrors || [];
    _this.protocolErrors = protocolErrors || [];
    _this.clientErrors = clientErrors || [];
    _this.networkError = networkError || null;
    _this.message = errorMessage || generateErrorMessage(_this);
    _this.extraInfo = extraInfo;
    _this.cause = (0, _tslib.__spreadArray)((0, _tslib.__spreadArray)((0, _tslib.__spreadArray)([networkError], graphQLErrors || [], true), protocolErrors || [], true), clientErrors || [], true).find(function (e) {
      return !!e;
    }) || null;
    // We're not using `Object.setPrototypeOf` here as it isn't fully
    // supported on Android (see issue #3236).
    _this.__proto__ = ApolloError.prototype;
    return _this;
  }
  return ApolloError;
}(Error);