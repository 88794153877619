"use strict";
"use es6";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEV = void 0;
Object.defineProperty(exports, "InvariantError", {
  enumerable: true,
  get: function () {
    return _invariantWrappers.InvariantError;
  }
});
exports.__DEV__ = void 0;
Object.defineProperty(exports, "global", {
  enumerable: true,
  get: function () {
    return _global.default;
  }
});
Object.defineProperty(exports, "invariant", {
  enumerable: true,
  get: function () {
    return _invariantWrappers.invariant;
  }
});
Object.defineProperty(exports, "maybe", {
  enumerable: true,
  get: function () {
    return _maybe.maybe;
  }
});
Object.defineProperty(exports, "newInvariantError", {
  enumerable: true,
  get: function () {
    return _invariantWrappers.newInvariantError;
  }
});
var _invariantWrappers = require("apollo-stack-hubspot/@apollo/client/utilities/globals/invariantWrappers");
var _maybe = require("apollo-stack-hubspot/@apollo/client/utilities/globals/maybe");
var _global = _interopRequireDefault(require("apollo-stack-hubspot/@apollo/client/utilities/globals/global"));
/**
 * @deprecated we do not use this internally anymore,
 * it is just exported for backwards compatibility
 */
// this file is extempt from automatic `__DEV__` replacement
// so we have to write it out here
// @ts-ignore
var DEV = exports.__DEV__ = exports.DEV = process.env.NODE_ENV !== "production";