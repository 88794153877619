"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  empty: true,
  from: true,
  split: true,
  concat: true,
  execute: true,
  ApolloLink: true
};
Object.defineProperty(exports, "ApolloLink", {
  enumerable: true,
  get: function () {
    return _ApolloLink.ApolloLink;
  }
});
Object.defineProperty(exports, "concat", {
  enumerable: true,
  get: function () {
    return _concat.concat;
  }
});
Object.defineProperty(exports, "empty", {
  enumerable: true,
  get: function () {
    return _empty.empty;
  }
});
Object.defineProperty(exports, "execute", {
  enumerable: true,
  get: function () {
    return _execute.execute;
  }
});
Object.defineProperty(exports, "from", {
  enumerable: true,
  get: function () {
    return _from.from;
  }
});
Object.defineProperty(exports, "split", {
  enumerable: true,
  get: function () {
    return _split.split;
  }
});
require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _empty = require("apollo-stack-hubspot/@apollo/client/link/core/empty");
var _from = require("apollo-stack-hubspot/@apollo/client/link/core/from");
var _split = require("apollo-stack-hubspot/@apollo/client/link/core/split");
var _concat = require("apollo-stack-hubspot/@apollo/client/link/core/concat");
var _execute = require("apollo-stack-hubspot/@apollo/client/link/core/execute");
var _ApolloLink = require("apollo-stack-hubspot/@apollo/client/link/core/ApolloLink");
var _types = require("apollo-stack-hubspot/@apollo/client/link/core/types");
Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _types[key];
    }
  });
});