"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  ApolloCache: true,
  Cache: true,
  MissingFieldError: true,
  isReference: true,
  makeReference: true,
  canonicalStringify: true,
  EntityStore: true,
  fieldNameFromStoreName: true,
  defaultDataIdFromObject: true,
  InMemoryCache: true,
  makeVar: true,
  cacheSlot: true,
  Policies: true,
  createFragmentRegistry: true
};
Object.defineProperty(exports, "ApolloCache", {
  enumerable: true,
  get: function () {
    return _cache.ApolloCache;
  }
});
Object.defineProperty(exports, "Cache", {
  enumerable: true,
  get: function () {
    return _Cache.Cache;
  }
});
Object.defineProperty(exports, "EntityStore", {
  enumerable: true,
  get: function () {
    return _entityStore.EntityStore;
  }
});
Object.defineProperty(exports, "InMemoryCache", {
  enumerable: true,
  get: function () {
    return _inMemoryCache.InMemoryCache;
  }
});
Object.defineProperty(exports, "MissingFieldError", {
  enumerable: true,
  get: function () {
    return _common.MissingFieldError;
  }
});
Object.defineProperty(exports, "Policies", {
  enumerable: true,
  get: function () {
    return _policies.Policies;
  }
});
Object.defineProperty(exports, "cacheSlot", {
  enumerable: true,
  get: function () {
    return _reactiveVars.cacheSlot;
  }
});
Object.defineProperty(exports, "canonicalStringify", {
  enumerable: true,
  get: function () {
    return _index2.canonicalStringify;
  }
});
Object.defineProperty(exports, "createFragmentRegistry", {
  enumerable: true,
  get: function () {
    return _fragmentRegistry.createFragmentRegistry;
  }
});
Object.defineProperty(exports, "defaultDataIdFromObject", {
  enumerable: true,
  get: function () {
    return _helpers.defaultDataIdFromObject;
  }
});
Object.defineProperty(exports, "fieldNameFromStoreName", {
  enumerable: true,
  get: function () {
    return _helpers.fieldNameFromStoreName;
  }
});
Object.defineProperty(exports, "isReference", {
  enumerable: true,
  get: function () {
    return _index2.isReference;
  }
});
Object.defineProperty(exports, "makeReference", {
  enumerable: true,
  get: function () {
    return _index2.makeReference;
  }
});
Object.defineProperty(exports, "makeVar", {
  enumerable: true,
  get: function () {
    return _reactiveVars.makeVar;
  }
});
require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _cache = require("apollo-stack-hubspot/@apollo/client/cache/core/cache");
var _Cache = require("apollo-stack-hubspot/@apollo/client/cache/core/types/Cache");
var _common = require("apollo-stack-hubspot/@apollo/client/cache/core/types/common");
var _index2 = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _entityStore = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/entityStore");
var _helpers = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/helpers");
var _inMemoryCache = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/inMemoryCache");
var _reactiveVars = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/reactiveVars");
var _policies = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/policies");
var _fragmentRegistry = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/fragmentRegistry");
var _types = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/types");
Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _types[key];
    }
  });
});