"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isMutationOperation = isMutationOperation;
exports.isQueryOperation = isQueryOperation;
exports.isSubscriptionOperation = isSubscriptionOperation;
var _getFromAST = require("apollo-stack-hubspot/@apollo/client/utilities/graphql/getFromAST");
function isOperation(document, operation) {
  var _a;
  return ((_a = (0, _getFromAST.getOperationDefinition)(document)) === null || _a === void 0 ? void 0 : _a.operation) === operation;
}
function isMutationOperation(document) {
  return isOperation(document, "mutation");
}
function isQueryOperation(document) {
  return isOperation(document, "query");
}
function isSubscriptionOperation(document) {
  return isOperation(document, "subscription");
}