"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  DEV: true,
  maybe: true,
  shouldInclude: true,
  hasDirectives: true,
  hasAnyDirectives: true,
  hasAllDirectives: true,
  hasClientExports: true,
  getDirectiveNames: true,
  getInclusionDirectives: true,
  DocumentTransform: true,
  createFragmentMap: true,
  getFragmentQueryDocument: true,
  getFragmentFromSelection: true,
  checkDocument: true,
  getOperationDefinition: true,
  getOperationName: true,
  getFragmentDefinitions: true,
  getQueryDefinition: true,
  getFragmentDefinition: true,
  getMainDefinition: true,
  getDefaultValues: true,
  print: true,
  makeReference: true,
  isDocumentNode: true,
  isReference: true,
  isField: true,
  isInlineFragment: true,
  valueToObjectRepresentation: true,
  storeKeyNameFromField: true,
  argumentsObjectFromField: true,
  resultKeyNameFromField: true,
  getStoreKeyName: true,
  getTypenameFromResult: true,
  addTypenameToDocument: true,
  buildQueryFromSelectionSet: true,
  removeDirectivesFromDocument: true,
  removeConnectionDirectiveFromDocument: true,
  removeArgumentsFromDocument: true,
  removeFragmentSpreadFromDocument: true,
  removeClientSetsFromDocument: true,
  isMutationOperation: true,
  isQueryOperation: true,
  isSubscriptionOperation: true,
  concatPagination: true,
  offsetLimitPagination: true,
  relayStylePagination: true,
  Observable: true,
  isStatefulPromise: true,
  createFulfilledPromise: true,
  createRejectedPromise: true,
  wrapPromiseWithState: true,
  canonicalStringify: true,
  omitDeep: true,
  stripTypename: true,
  AutoCleanedStrongCache: true,
  AutoCleanedWeakCache: true,
  cacheSizes: true
};
Object.defineProperty(exports, "AutoCleanedStrongCache", {
  enumerable: true,
  get: function () {
    return _index2.AutoCleanedStrongCache;
  }
});
Object.defineProperty(exports, "AutoCleanedWeakCache", {
  enumerable: true,
  get: function () {
    return _index2.AutoCleanedWeakCache;
  }
});
Object.defineProperty(exports, "DEV", {
  enumerable: true,
  get: function () {
    return _index.DEV;
  }
});
Object.defineProperty(exports, "DocumentTransform", {
  enumerable: true,
  get: function () {
    return _DocumentTransform.DocumentTransform;
  }
});
Object.defineProperty(exports, "Observable", {
  enumerable: true,
  get: function () {
    return _Observable.Observable;
  }
});
Object.defineProperty(exports, "addTypenameToDocument", {
  enumerable: true,
  get: function () {
    return _transform.addTypenameToDocument;
  }
});
Object.defineProperty(exports, "argumentsObjectFromField", {
  enumerable: true,
  get: function () {
    return _storeUtils.argumentsObjectFromField;
  }
});
Object.defineProperty(exports, "buildQueryFromSelectionSet", {
  enumerable: true,
  get: function () {
    return _transform.buildQueryFromSelectionSet;
  }
});
Object.defineProperty(exports, "cacheSizes", {
  enumerable: true,
  get: function () {
    return _index2.cacheSizes;
  }
});
Object.defineProperty(exports, "canonicalStringify", {
  enumerable: true,
  get: function () {
    return _canonicalStringify.canonicalStringify;
  }
});
Object.defineProperty(exports, "checkDocument", {
  enumerable: true,
  get: function () {
    return _getFromAST.checkDocument;
  }
});
Object.defineProperty(exports, "concatPagination", {
  enumerable: true,
  get: function () {
    return _pagination.concatPagination;
  }
});
Object.defineProperty(exports, "createFragmentMap", {
  enumerable: true,
  get: function () {
    return _fragments.createFragmentMap;
  }
});
Object.defineProperty(exports, "createFulfilledPromise", {
  enumerable: true,
  get: function () {
    return _decoration.createFulfilledPromise;
  }
});
Object.defineProperty(exports, "createRejectedPromise", {
  enumerable: true,
  get: function () {
    return _decoration.createRejectedPromise;
  }
});
Object.defineProperty(exports, "getDefaultValues", {
  enumerable: true,
  get: function () {
    return _getFromAST.getDefaultValues;
  }
});
Object.defineProperty(exports, "getDirectiveNames", {
  enumerable: true,
  get: function () {
    return _directives.getDirectiveNames;
  }
});
Object.defineProperty(exports, "getFragmentDefinition", {
  enumerable: true,
  get: function () {
    return _getFromAST.getFragmentDefinition;
  }
});
Object.defineProperty(exports, "getFragmentDefinitions", {
  enumerable: true,
  get: function () {
    return _getFromAST.getFragmentDefinitions;
  }
});
Object.defineProperty(exports, "getFragmentFromSelection", {
  enumerable: true,
  get: function () {
    return _fragments.getFragmentFromSelection;
  }
});
Object.defineProperty(exports, "getFragmentQueryDocument", {
  enumerable: true,
  get: function () {
    return _fragments.getFragmentQueryDocument;
  }
});
Object.defineProperty(exports, "getInclusionDirectives", {
  enumerable: true,
  get: function () {
    return _directives.getInclusionDirectives;
  }
});
Object.defineProperty(exports, "getMainDefinition", {
  enumerable: true,
  get: function () {
    return _getFromAST.getMainDefinition;
  }
});
Object.defineProperty(exports, "getOperationDefinition", {
  enumerable: true,
  get: function () {
    return _getFromAST.getOperationDefinition;
  }
});
Object.defineProperty(exports, "getOperationName", {
  enumerable: true,
  get: function () {
    return _getFromAST.getOperationName;
  }
});
Object.defineProperty(exports, "getQueryDefinition", {
  enumerable: true,
  get: function () {
    return _getFromAST.getQueryDefinition;
  }
});
Object.defineProperty(exports, "getStoreKeyName", {
  enumerable: true,
  get: function () {
    return _storeUtils.getStoreKeyName;
  }
});
Object.defineProperty(exports, "getTypenameFromResult", {
  enumerable: true,
  get: function () {
    return _storeUtils.getTypenameFromResult;
  }
});
Object.defineProperty(exports, "hasAllDirectives", {
  enumerable: true,
  get: function () {
    return _directives.hasAllDirectives;
  }
});
Object.defineProperty(exports, "hasAnyDirectives", {
  enumerable: true,
  get: function () {
    return _directives.hasAnyDirectives;
  }
});
Object.defineProperty(exports, "hasClientExports", {
  enumerable: true,
  get: function () {
    return _directives.hasClientExports;
  }
});
Object.defineProperty(exports, "hasDirectives", {
  enumerable: true,
  get: function () {
    return _directives.hasDirectives;
  }
});
Object.defineProperty(exports, "isDocumentNode", {
  enumerable: true,
  get: function () {
    return _storeUtils.isDocumentNode;
  }
});
Object.defineProperty(exports, "isField", {
  enumerable: true,
  get: function () {
    return _storeUtils.isField;
  }
});
Object.defineProperty(exports, "isInlineFragment", {
  enumerable: true,
  get: function () {
    return _storeUtils.isInlineFragment;
  }
});
Object.defineProperty(exports, "isMutationOperation", {
  enumerable: true,
  get: function () {
    return _operations.isMutationOperation;
  }
});
Object.defineProperty(exports, "isQueryOperation", {
  enumerable: true,
  get: function () {
    return _operations.isQueryOperation;
  }
});
Object.defineProperty(exports, "isReference", {
  enumerable: true,
  get: function () {
    return _storeUtils.isReference;
  }
});
Object.defineProperty(exports, "isStatefulPromise", {
  enumerable: true,
  get: function () {
    return _decoration.isStatefulPromise;
  }
});
Object.defineProperty(exports, "isSubscriptionOperation", {
  enumerable: true,
  get: function () {
    return _operations.isSubscriptionOperation;
  }
});
Object.defineProperty(exports, "makeReference", {
  enumerable: true,
  get: function () {
    return _storeUtils.makeReference;
  }
});
Object.defineProperty(exports, "maybe", {
  enumerable: true,
  get: function () {
    return _index.maybe;
  }
});
Object.defineProperty(exports, "offsetLimitPagination", {
  enumerable: true,
  get: function () {
    return _pagination.offsetLimitPagination;
  }
});
Object.defineProperty(exports, "omitDeep", {
  enumerable: true,
  get: function () {
    return _omitDeep.omitDeep;
  }
});
Object.defineProperty(exports, "print", {
  enumerable: true,
  get: function () {
    return _print.print;
  }
});
Object.defineProperty(exports, "relayStylePagination", {
  enumerable: true,
  get: function () {
    return _pagination.relayStylePagination;
  }
});
Object.defineProperty(exports, "removeArgumentsFromDocument", {
  enumerable: true,
  get: function () {
    return _transform.removeArgumentsFromDocument;
  }
});
Object.defineProperty(exports, "removeClientSetsFromDocument", {
  enumerable: true,
  get: function () {
    return _transform.removeClientSetsFromDocument;
  }
});
Object.defineProperty(exports, "removeConnectionDirectiveFromDocument", {
  enumerable: true,
  get: function () {
    return _transform.removeConnectionDirectiveFromDocument;
  }
});
Object.defineProperty(exports, "removeDirectivesFromDocument", {
  enumerable: true,
  get: function () {
    return _transform.removeDirectivesFromDocument;
  }
});
Object.defineProperty(exports, "removeFragmentSpreadFromDocument", {
  enumerable: true,
  get: function () {
    return _transform.removeFragmentSpreadFromDocument;
  }
});
Object.defineProperty(exports, "resultKeyNameFromField", {
  enumerable: true,
  get: function () {
    return _storeUtils.resultKeyNameFromField;
  }
});
Object.defineProperty(exports, "shouldInclude", {
  enumerable: true,
  get: function () {
    return _directives.shouldInclude;
  }
});
Object.defineProperty(exports, "storeKeyNameFromField", {
  enumerable: true,
  get: function () {
    return _storeUtils.storeKeyNameFromField;
  }
});
Object.defineProperty(exports, "stripTypename", {
  enumerable: true,
  get: function () {
    return _stripTypename.stripTypename;
  }
});
Object.defineProperty(exports, "valueToObjectRepresentation", {
  enumerable: true,
  get: function () {
    return _storeUtils.valueToObjectRepresentation;
  }
});
Object.defineProperty(exports, "wrapPromiseWithState", {
  enumerable: true,
  get: function () {
    return _decoration.wrapPromiseWithState;
  }
});
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _directives = require("apollo-stack-hubspot/@apollo/client/utilities/graphql/directives");
var _DocumentTransform = require("apollo-stack-hubspot/@apollo/client/utilities/graphql/DocumentTransform");
var _fragments = require("apollo-stack-hubspot/@apollo/client/utilities/graphql/fragments");
var _getFromAST = require("apollo-stack-hubspot/@apollo/client/utilities/graphql/getFromAST");
var _print = require("apollo-stack-hubspot/@apollo/client/utilities/graphql/print");
var _storeUtils = require("apollo-stack-hubspot/@apollo/client/utilities/graphql/storeUtils");
var _transform = require("apollo-stack-hubspot/@apollo/client/utilities/graphql/transform");
var _operations = require("apollo-stack-hubspot/@apollo/client/utilities/graphql/operations");
var _pagination = require("apollo-stack-hubspot/@apollo/client/utilities/policies/pagination");
var _Observable = require("apollo-stack-hubspot/@apollo/client/utilities/observables/Observable");
var _decoration = require("apollo-stack-hubspot/@apollo/client/utilities/promises/decoration");
var _mergeDeep = require("apollo-stack-hubspot/@apollo/client/utilities/common/mergeDeep");
Object.keys(_mergeDeep).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _mergeDeep[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _mergeDeep[key];
    }
  });
});
var _cloneDeep = require("apollo-stack-hubspot/@apollo/client/utilities/common/cloneDeep");
Object.keys(_cloneDeep).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _cloneDeep[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _cloneDeep[key];
    }
  });
});
var _maybeDeepFreeze = require("apollo-stack-hubspot/@apollo/client/utilities/common/maybeDeepFreeze");
Object.keys(_maybeDeepFreeze).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _maybeDeepFreeze[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _maybeDeepFreeze[key];
    }
  });
});
var _iteration = require("apollo-stack-hubspot/@apollo/client/utilities/observables/iteration");
Object.keys(_iteration).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _iteration[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _iteration[key];
    }
  });
});
var _asyncMap = require("apollo-stack-hubspot/@apollo/client/utilities/observables/asyncMap");
Object.keys(_asyncMap).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _asyncMap[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _asyncMap[key];
    }
  });
});
var _Concast = require("apollo-stack-hubspot/@apollo/client/utilities/observables/Concast");
Object.keys(_Concast).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Concast[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Concast[key];
    }
  });
});
var _subclassing = require("apollo-stack-hubspot/@apollo/client/utilities/observables/subclassing");
Object.keys(_subclassing).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _subclassing[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _subclassing[key];
    }
  });
});
var _arrays = require("apollo-stack-hubspot/@apollo/client/utilities/common/arrays");
Object.keys(_arrays).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _arrays[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _arrays[key];
    }
  });
});
var _objects = require("apollo-stack-hubspot/@apollo/client/utilities/common/objects");
Object.keys(_objects).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _objects[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _objects[key];
    }
  });
});
var _errorHandling = require("apollo-stack-hubspot/@apollo/client/utilities/common/errorHandling");
Object.keys(_errorHandling).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _errorHandling[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _errorHandling[key];
    }
  });
});
var _canUse = require("apollo-stack-hubspot/@apollo/client/utilities/common/canUse");
Object.keys(_canUse).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _canUse[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _canUse[key];
    }
  });
});
var _compact = require("apollo-stack-hubspot/@apollo/client/utilities/common/compact");
Object.keys(_compact).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _compact[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _compact[key];
    }
  });
});
var _makeUniqueId = require("apollo-stack-hubspot/@apollo/client/utilities/common/makeUniqueId");
Object.keys(_makeUniqueId).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _makeUniqueId[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _makeUniqueId[key];
    }
  });
});
var _stringifyForDisplay = require("apollo-stack-hubspot/@apollo/client/utilities/common/stringifyForDisplay");
Object.keys(_stringifyForDisplay).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _stringifyForDisplay[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _stringifyForDisplay[key];
    }
  });
});
var _mergeOptions = require("apollo-stack-hubspot/@apollo/client/utilities/common/mergeOptions");
Object.keys(_mergeOptions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _mergeOptions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _mergeOptions[key];
    }
  });
});
var _incrementalResult = require("apollo-stack-hubspot/@apollo/client/utilities/common/incrementalResult");
Object.keys(_incrementalResult).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _incrementalResult[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _incrementalResult[key];
    }
  });
});
var _canonicalStringify = require("apollo-stack-hubspot/@apollo/client/utilities/common/canonicalStringify");
var _omitDeep = require("apollo-stack-hubspot/@apollo/client/utilities/common/omitDeep");
var _stripTypename = require("apollo-stack-hubspot/@apollo/client/utilities/common/stripTypename");
var _IsStrictlyAny = require("apollo-stack-hubspot/@apollo/client/utilities/types/IsStrictlyAny");
Object.keys(_IsStrictlyAny).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _IsStrictlyAny[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _IsStrictlyAny[key];
    }
  });
});
var _index2 = require("apollo-stack-hubspot/@apollo/client/utilities/caching/index");