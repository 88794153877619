"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serializeFetchParameter = void 0;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var serializeFetchParameter = exports.serializeFetchParameter = function serializeFetchParameter(p, label) {
  var serialized;
  try {
    serialized = JSON.stringify(p);
  } catch (e) {
    var parseError = (0, _index.newInvariantError)(40, label, e.message);
    parseError.parseError = e;
    throw parseError;
  }
  return serialized;
};