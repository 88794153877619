"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGraphQLErrorsFromResult = getGraphQLErrorsFromResult;
exports.graphQLResultHasError = graphQLResultHasError;
var _arrays = require("apollo-stack-hubspot/@apollo/client/utilities/common/arrays");
var _incrementalResult = require("apollo-stack-hubspot/@apollo/client/utilities/common/incrementalResult");
function graphQLResultHasError(result) {
  var errors = getGraphQLErrorsFromResult(result);
  return (0, _arrays.isNonEmptyArray)(errors);
}
function getGraphQLErrorsFromResult(result) {
  var graphQLErrors = (0, _arrays.isNonEmptyArray)(result.errors) ? result.errors.slice(0) : [];
  if ((0, _incrementalResult.isExecutionPatchIncrementalResult)(result) && (0, _arrays.isNonEmptyArray)(result.incremental)) {
    result.incremental.forEach(function (incrementalResult) {
      if (incrementalResult.errors) {
        graphQLErrors.push.apply(graphQLErrors, incrementalResult.errors);
      }
    });
  }
  return graphQLErrors;
}