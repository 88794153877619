"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isApolloPayloadResult = isApolloPayloadResult;
exports.isExecutionPatchIncrementalResult = isExecutionPatchIncrementalResult;
exports.isExecutionPatchInitialResult = isExecutionPatchInitialResult;
exports.isExecutionPatchResult = isExecutionPatchResult;
exports.mergeIncrementalData = mergeIncrementalData;
var _objects = require("apollo-stack-hubspot/@apollo/client/utilities/common/objects");
var _arrays = require("apollo-stack-hubspot/@apollo/client/utilities/common/arrays");
var _mergeDeep = require("apollo-stack-hubspot/@apollo/client/utilities/common/mergeDeep");
function isExecutionPatchIncrementalResult(value) {
  return "incremental" in value;
}
function isExecutionPatchInitialResult(value) {
  return "hasNext" in value && "data" in value;
}
function isExecutionPatchResult(value) {
  return isExecutionPatchIncrementalResult(value) || isExecutionPatchInitialResult(value);
}
// This function detects an Apollo payload result before it is transformed
// into a FetchResult via HttpLink; it cannot detect an ApolloPayloadResult
// once it leaves the link chain.
function isApolloPayloadResult(value) {
  return (0, _objects.isNonNullObject)(value) && "payload" in value;
}
function mergeIncrementalData(prevResult, result) {
  var mergedData = prevResult;
  var merger = new _mergeDeep.DeepMerger();
  if (isExecutionPatchIncrementalResult(result) && (0, _arrays.isNonEmptyArray)(result.incremental)) {
    result.incremental.forEach(function (_a) {
      var data = _a.data,
        path = _a.path;
      for (var i = path.length - 1; i >= 0; --i) {
        var key = path[i];
        var isNumericKey = !isNaN(+key);
        var parent_1 = isNumericKey ? [] : {};
        parent_1[key] = data;
        data = parent_1;
      }
      mergedData = merger.merge(mergedData, data);
    });
  }
  return mergedData;
}