"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateOperation = validateOperation;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
function validateOperation(operation) {
  var OPERATION_FIELDS = ["query", "operationName", "variables", "extensions", "context"];
  for (var _i = 0, _a = Object.keys(operation); _i < _a.length; _i++) {
    var key = _a[_i];
    if (OPERATION_FIELDS.indexOf(key) < 0) {
      throw (0, _index.newInvariantError)(44, key);
    }
  }
  return operation;
}