"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.maybe = maybe;
function maybe(thunk) {
  try {
    return thunk();
  } catch (_a) {}
}