"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.canUseWeakSet = exports.canUseWeakMap = exports.canUseSymbol = exports.canUseLayoutEffect = exports.canUseDOM = exports.canUseAsyncIteratorSymbol = void 0;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var isReactNative = (0, _index.maybe)(function () {
  return navigator.product;
}) == "ReactNative";
var canUseWeakMap = exports.canUseWeakMap = typeof WeakMap === "function" && !(isReactNative && !global.HermesInternal);
var canUseWeakSet = exports.canUseWeakSet = typeof WeakSet === "function";
var canUseSymbol = exports.canUseSymbol = typeof Symbol === "function" && typeof Symbol.for === "function";
var canUseAsyncIteratorSymbol = exports.canUseAsyncIteratorSymbol = canUseSymbol && Symbol.asyncIterator;
var canUseDOM = exports.canUseDOM = typeof (0, _index.maybe)(function () {
  return window.document.createElement;
}) === "function";
var usingJSDOM =
// Following advice found in this comment from @domenic (maintainer of jsdom):
// https://github.com/jsdom/jsdom/issues/1537#issuecomment-229405327
//
// Since we control the version of Jest and jsdom used when running Apollo
// Client tests, and that version is recent enought to include " jsdom/x.y.z"
// at the end of the user agent string, I believe this case is all we need to
// check. Testing for "Node.js" was recommended for backwards compatibility
// with older version of jsdom, but we don't have that problem.
(0, _index.maybe)(function () {
  return navigator.userAgent.indexOf("jsdom") >= 0;
}) || false;
// Our tests should all continue to pass if we remove this !usingJSDOM
// condition, thereby allowing useLayoutEffect when using jsdom. Unfortunately,
// if we allow useLayoutEffect, then useSyncExternalStore generates many
// warnings about useLayoutEffect doing nothing on the server. While these
// warnings are harmless, this !usingJSDOM condition seems to be the best way to
// prevent them (i.e. skipping useLayoutEffect when using jsdom).
var canUseLayoutEffect = exports.canUseLayoutEffect = (canUseDOM || isReactNative) && !usingJSDOM;