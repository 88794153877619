"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TypeOrFieldNameRegExp = void 0;
exports.defaultDataIdFromObject = defaultDataIdFromObject;
exports.extractFragmentContext = extractFragmentContext;
exports.fieldNameFromStoreName = fieldNameFromStoreName;
exports.getTypenameFromStoreObject = getTypenameFromStoreObject;
exports.hasOwn = void 0;
Object.defineProperty(exports, "isArray", {
  enumerable: true,
  get: function () {
    return _index.isArray;
  }
});
exports.isNullish = isNullish;
exports.makeProcessedFieldsMerger = makeProcessedFieldsMerger;
exports.normalizeConfig = normalizeConfig;
exports.selectionSetMatchesResult = selectionSetMatchesResult;
exports.shouldCanonizeResults = shouldCanonizeResults;
exports.storeValueIsStoreObject = storeValueIsStoreObject;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var hasOwn = exports.hasOwn = Object.prototype.hasOwnProperty;
function isNullish(value) {
  return value === null || value === void 0;
}
function defaultDataIdFromObject(_a, context) {
  var __typename = _a.__typename,
    id = _a.id,
    _id = _a._id;
  if (typeof __typename === "string") {
    if (context) {
      context.keyObject = !isNullish(id) ? {
        id: id
      } : !isNullish(_id) ? {
        _id: _id
      } : void 0;
    }
    // If there is no object.id, fall back to object._id.
    if (isNullish(id) && !isNullish(_id)) {
      id = _id;
    }
    if (!isNullish(id)) {
      return "".concat(__typename, ":").concat(typeof id === "number" || typeof id === "string" ? id : JSON.stringify(id));
    }
  }
}
var defaultConfig = {
  dataIdFromObject: defaultDataIdFromObject,
  addTypename: true,
  resultCaching: true,
  // Thanks to the shouldCanonizeResults helper, this should be the only line
  // you have to change to reenable canonization by default in the future.
  canonizeResults: false
};
function normalizeConfig(config) {
  return (0, _index.compact)(defaultConfig, config);
}
function shouldCanonizeResults(config) {
  var value = config.canonizeResults;
  return value === void 0 ? defaultConfig.canonizeResults : value;
}
function getTypenameFromStoreObject(store, objectOrReference) {
  return (0, _index.isReference)(objectOrReference) ? store.get(objectOrReference.__ref, "__typename") : objectOrReference && objectOrReference.__typename;
}
var TypeOrFieldNameRegExp = exports.TypeOrFieldNameRegExp = /^[_a-z][_0-9a-z]*/i;
function fieldNameFromStoreName(storeFieldName) {
  var match = storeFieldName.match(TypeOrFieldNameRegExp);
  return match ? match[0] : storeFieldName;
}
function selectionSetMatchesResult(selectionSet, result, variables) {
  if ((0, _index.isNonNullObject)(result)) {
    return (0, _index.isArray)(result) ? result.every(function (item) {
      return selectionSetMatchesResult(selectionSet, item, variables);
    }) : selectionSet.selections.every(function (field) {
      if ((0, _index.isField)(field) && (0, _index.shouldInclude)(field, variables)) {
        var key = (0, _index.resultKeyNameFromField)(field);
        return hasOwn.call(result, key) && (!field.selectionSet || selectionSetMatchesResult(field.selectionSet, result[key], variables));
      }
      // If the selection has been skipped with @skip(true) or
      // @include(false), it should not count against the matching. If
      // the selection is not a field, it must be a fragment (inline or
      // named). We will determine if selectionSetMatchesResult for that
      // fragment when we get to it, so for now we return true.
      return true;
    });
  }
  return false;
}
function storeValueIsStoreObject(value) {
  return (0, _index.isNonNullObject)(value) && !(0, _index.isReference)(value) && !(0, _index.isArray)(value);
}
function makeProcessedFieldsMerger() {
  return new _index.DeepMerger();
}
function extractFragmentContext(document, fragments) {
  // FragmentMap consisting only of fragments defined directly in document, not
  // including other fragments registered in the FragmentRegistry.
  var fragmentMap = (0, _index.createFragmentMap)((0, _index.getFragmentDefinitions)(document));
  return {
    fragmentMap: fragmentMap,
    lookupFragment: function (name) {
      var def = fragmentMap[name];
      if (!def && fragments) {
        def = fragments.lookup(name);
      }
      return def || null;
    }
  };
}