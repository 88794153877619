"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApolloLink = void 0;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _index3 = require("apollo-stack-hubspot/@apollo/client/link/utils/index");
function passthrough(op, forward) {
  return forward ? forward(op) : _index2.Observable.of();
}
function toLink(handler) {
  return typeof handler === "function" ? new ApolloLink(handler) : handler;
}
function isTerminating(link) {
  return link.request.length <= 1;
}
var ApolloLink = exports.ApolloLink = /** @class */function () {
  function ApolloLink(request) {
    if (request) this.request = request;
  }
  ApolloLink.empty = function () {
    return new ApolloLink(function () {
      return _index2.Observable.of();
    });
  };
  ApolloLink.from = function (links) {
    if (links.length === 0) return ApolloLink.empty();
    return links.map(toLink).reduce(function (x, y) {
      return x.concat(y);
    });
  };
  ApolloLink.split = function (test, left, right) {
    var leftLink = toLink(left);
    var rightLink = toLink(right || new ApolloLink(passthrough));
    var ret;
    if (isTerminating(leftLink) && isTerminating(rightLink)) {
      ret = new ApolloLink(function (operation) {
        return test(operation) ? leftLink.request(operation) || _index2.Observable.of() : rightLink.request(operation) || _index2.Observable.of();
      });
    } else {
      ret = new ApolloLink(function (operation, forward) {
        return test(operation) ? leftLink.request(operation, forward) || _index2.Observable.of() : rightLink.request(operation, forward) || _index2.Observable.of();
      });
    }
    return Object.assign(ret, {
      left: leftLink,
      right: rightLink
    });
  };
  ApolloLink.execute = function (link, operation) {
    return link.request((0, _index3.createOperation)(operation.context, (0, _index3.transformOperation)((0, _index3.validateOperation)(operation)))) || _index2.Observable.of();
  };
  ApolloLink.concat = function (first, second) {
    var firstLink = toLink(first);
    if (isTerminating(firstLink)) {
      process.env.NODE_ENV !== "production" && _index.invariant.warn(36, firstLink);
      return firstLink;
    }
    var nextLink = toLink(second);
    var ret;
    if (isTerminating(nextLink)) {
      ret = new ApolloLink(function (operation) {
        return firstLink.request(operation, function (op) {
          return nextLink.request(op) || _index2.Observable.of();
        }) || _index2.Observable.of();
      });
    } else {
      ret = new ApolloLink(function (operation, forward) {
        return firstLink.request(operation, function (op) {
          return nextLink.request(op, forward) || _index2.Observable.of();
        }) || _index2.Observable.of();
      });
    }
    return Object.assign(ret, {
      left: firstLink,
      right: nextLink
    });
  };
  ApolloLink.prototype.split = function (test, left, right) {
    return this.concat(ApolloLink.split(test, left, right || new ApolloLink(passthrough)));
  };
  ApolloLink.prototype.concat = function (next) {
    return ApolloLink.concat(this, next);
  };
  ApolloLink.prototype.request = function (operation, forward) {
    throw (0, _index.newInvariantError)(37);
  };
  ApolloLink.prototype.onError = function (error, observer) {
    if (observer && observer.error) {
      observer.error(error);
      // Returning false indicates that observer.error does not need to be
      // called again, since it was already called (on the previous line).
      // Calling observer.error again would not cause any real problems,
      // since only the first call matters, but custom onError functions
      // might have other reasons for wanting to prevent the default
      // behavior by returning false.
      return false;
    }
    // Throw errors will be passed to observer.error.
    throw error;
  };
  ApolloLink.prototype.setOnError = function (fn) {
    this.onError = fn;
    return this;
  };
  return ApolloLink;
}();