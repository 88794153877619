"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toApolloError = toApolloError;
exports.useSuspenseQuery = useSuspenseQuery;
exports.useWatchQueryOptions = useWatchQueryOptions;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var React = _interopRequireWildcard(require("react"));
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/core/index");
var _index3 = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _useApolloClient = require("apollo-stack-hubspot/@apollo/client/react/hooks/useApolloClient");
var _index4 = require("apollo-stack-hubspot/@apollo/client/react/parser/index");
var _index5 = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/index");
var _index6 = require("apollo-stack-hubspot/@apollo/client/react/internal/index");
var _index7 = require("apollo-stack-hubspot/@apollo/client/cache/index");
var _constants = require("apollo-stack-hubspot/@apollo/client/react/hooks/constants");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function useSuspenseQuery(query, options) {
  if (options === void 0) {
    options = Object.create(null);
  }
  return (0, _index5.wrapHook)("useSuspenseQuery", _useSuspenseQuery, (0, _useApolloClient.useApolloClient)(typeof options === "object" ? options.client : undefined))(query, options);
}
function _useSuspenseQuery(query, options) {
  var client = (0, _useApolloClient.useApolloClient)(options.client);
  var suspenseCache = (0, _index6.getSuspenseCache)(client);
  var watchQueryOptions = useWatchQueryOptions({
    client: client,
    query: query,
    options: options
  });
  var fetchPolicy = watchQueryOptions.fetchPolicy,
    variables = watchQueryOptions.variables;
  var _a = options.queryKey,
    queryKey = _a === void 0 ? [] : _a;
  var cacheKey = (0, _tslib.__spreadArray)([query, (0, _index7.canonicalStringify)(variables)], [].concat(queryKey), true);
  var queryRef = suspenseCache.getQueryRef(cacheKey, function () {
    return client.watchQuery(watchQueryOptions);
  });
  var _b = React.useState([queryRef.key, queryRef.promise]),
    current = _b[0],
    setPromise = _b[1];
  // This saves us a re-execution of the render function when a variable changed.
  if (current[0] !== queryRef.key) {
    current[0] = queryRef.key;
    current[1] = queryRef.promise;
  }
  var promise = current[1];
  if (queryRef.didChangeOptions(watchQueryOptions)) {
    current[1] = promise = queryRef.applyOptions(watchQueryOptions);
  }
  React.useEffect(function () {
    var dispose = queryRef.retain();
    var removeListener = queryRef.listen(function (promise) {
      setPromise([queryRef.key, promise]);
    });
    return function () {
      removeListener();
      dispose();
    };
  }, [queryRef]);
  var skipResult = React.useMemo(function () {
    var error = toApolloError(queryRef.result);
    return {
      loading: false,
      data: queryRef.result.data,
      networkStatus: error ? _index2.NetworkStatus.error : _index2.NetworkStatus.ready,
      error: error
    };
  }, [queryRef.result]);
  var result = fetchPolicy === "standby" ? skipResult : (0, _index5.__use)(promise);
  var fetchMore = React.useCallback(function (options) {
    var promise = queryRef.fetchMore(options);
    setPromise([queryRef.key, queryRef.promise]);
    return promise;
  }, [queryRef]);
  var refetch = React.useCallback(function (variables) {
    var promise = queryRef.refetch(variables);
    setPromise([queryRef.key, queryRef.promise]);
    return promise;
  }, [queryRef]);
  var subscribeToMore = queryRef.observable.subscribeToMore;
  return React.useMemo(function () {
    return {
      client: client,
      data: result.data,
      error: toApolloError(result),
      networkStatus: result.networkStatus,
      fetchMore: fetchMore,
      refetch: refetch,
      subscribeToMore: subscribeToMore
    };
  }, [client, fetchMore, refetch, result, subscribeToMore]);
}
function validateOptions(options) {
  var query = options.query,
    fetchPolicy = options.fetchPolicy,
    returnPartialData = options.returnPartialData;
  (0, _index4.verifyDocumentType)(query, _index4.DocumentType.Query);
  validateFetchPolicy(fetchPolicy);
  validatePartialDataReturn(fetchPolicy, returnPartialData);
}
function validateFetchPolicy(fetchPolicy) {
  if (fetchPolicy === void 0) {
    fetchPolicy = "cache-first";
  }
  var supportedFetchPolicies = ["cache-first", "network-only", "no-cache", "cache-and-network"];
  (0, _index.invariant)(supportedFetchPolicies.includes(fetchPolicy), 58, fetchPolicy);
}
function validatePartialDataReturn(fetchPolicy, returnPartialData) {
  if (fetchPolicy === "no-cache" && returnPartialData) {
    process.env.NODE_ENV !== "production" && _index.invariant.warn(59);
  }
}
function toApolloError(result) {
  return (0, _index3.isNonEmptyArray)(result.errors) ? new _index2.ApolloError({
    graphQLErrors: result.errors
  }) : result.error;
}
function useWatchQueryOptions(_a) {
  var client = _a.client,
    query = _a.query,
    options = _a.options;
  return (0, _index5.useDeepMemo)(function () {
    var _a;
    if (options === _constants.skipToken) {
      return {
        query: query,
        fetchPolicy: "standby"
      };
    }
    var fetchPolicy = options.fetchPolicy || ((_a = client.defaultOptions.watchQuery) === null || _a === void 0 ? void 0 : _a.fetchPolicy) || "cache-first";
    var watchQueryOptions = (0, _tslib.__assign)((0, _tslib.__assign)({}, options), {
      fetchPolicy: fetchPolicy,
      query: query,
      notifyOnNetworkStatusChange: false,
      nextFetchPolicy: void 0
    });
    if (process.env.NODE_ENV !== "production") {
      validateOptions(watchQueryOptions);
    }
    // Assign the updated fetch policy after our validation since `standby` is
    // not a supported fetch policy on its own without the use of `skip`.
    if (options.skip) {
      watchQueryOptions.fetchPolicy = "standby";
    }
    return watchQueryOptions;
  }, [client, options, query]);
}