"use strict";
"use es6";

/* Core */
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  ApolloClient: true,
  mergeOptions: true,
  ObservableQuery: true,
  NetworkStatus: true,
  isNetworkRequestSettled: true,
  isApolloError: true,
  ApolloError: true,
  Cache: true,
  ApolloCache: true,
  InMemoryCache: true,
  MissingFieldError: true,
  defaultDataIdFromObject: true,
  makeVar: true,
  fromError: true,
  toPromise: true,
  fromPromise: true,
  throwServerError: true,
  DocumentTransform: true,
  Observable: true,
  isReference: true,
  makeReference: true,
  setLogVerbosity: true,
  gql: true,
  resetCaches: true,
  disableFragmentWarnings: true,
  enableExperimentalFragmentVariables: true,
  disableExperimentalFragmentVariables: true
};
Object.defineProperty(exports, "ApolloCache", {
  enumerable: true,
  get: function () {
    return _index2.ApolloCache;
  }
});
Object.defineProperty(exports, "ApolloClient", {
  enumerable: true,
  get: function () {
    return _ApolloClient.ApolloClient;
  }
});
Object.defineProperty(exports, "ApolloError", {
  enumerable: true,
  get: function () {
    return _index.ApolloError;
  }
});
Object.defineProperty(exports, "Cache", {
  enumerable: true,
  get: function () {
    return _index2.Cache;
  }
});
Object.defineProperty(exports, "DocumentTransform", {
  enumerable: true,
  get: function () {
    return _index6.DocumentTransform;
  }
});
Object.defineProperty(exports, "InMemoryCache", {
  enumerable: true,
  get: function () {
    return _index2.InMemoryCache;
  }
});
Object.defineProperty(exports, "MissingFieldError", {
  enumerable: true,
  get: function () {
    return _index2.MissingFieldError;
  }
});
Object.defineProperty(exports, "NetworkStatus", {
  enumerable: true,
  get: function () {
    return _networkStatus.NetworkStatus;
  }
});
Object.defineProperty(exports, "Observable", {
  enumerable: true,
  get: function () {
    return _index6.Observable;
  }
});
Object.defineProperty(exports, "ObservableQuery", {
  enumerable: true,
  get: function () {
    return _ObservableQuery.ObservableQuery;
  }
});
Object.defineProperty(exports, "defaultDataIdFromObject", {
  enumerable: true,
  get: function () {
    return _index2.defaultDataIdFromObject;
  }
});
Object.defineProperty(exports, "disableExperimentalFragmentVariables", {
  enumerable: true,
  get: function () {
    return _index7.disableExperimentalFragmentVariables;
  }
});
Object.defineProperty(exports, "disableFragmentWarnings", {
  enumerable: true,
  get: function () {
    return _index7.disableFragmentWarnings;
  }
});
Object.defineProperty(exports, "enableExperimentalFragmentVariables", {
  enumerable: true,
  get: function () {
    return _index7.enableExperimentalFragmentVariables;
  }
});
Object.defineProperty(exports, "fromError", {
  enumerable: true,
  get: function () {
    return _index5.fromError;
  }
});
Object.defineProperty(exports, "fromPromise", {
  enumerable: true,
  get: function () {
    return _index5.fromPromise;
  }
});
Object.defineProperty(exports, "gql", {
  enumerable: true,
  get: function () {
    return _index7.gql;
  }
});
Object.defineProperty(exports, "isApolloError", {
  enumerable: true,
  get: function () {
    return _index.isApolloError;
  }
});
Object.defineProperty(exports, "isNetworkRequestSettled", {
  enumerable: true,
  get: function () {
    return _networkStatus.isNetworkRequestSettled;
  }
});
Object.defineProperty(exports, "isReference", {
  enumerable: true,
  get: function () {
    return _index6.isReference;
  }
});
Object.defineProperty(exports, "makeReference", {
  enumerable: true,
  get: function () {
    return _index6.makeReference;
  }
});
Object.defineProperty(exports, "makeVar", {
  enumerable: true,
  get: function () {
    return _index2.makeVar;
  }
});
Object.defineProperty(exports, "mergeOptions", {
  enumerable: true,
  get: function () {
    return _ApolloClient.mergeOptions;
  }
});
Object.defineProperty(exports, "resetCaches", {
  enumerable: true,
  get: function () {
    return _index7.resetCaches;
  }
});
Object.defineProperty(exports, "setLogVerbosity", {
  enumerable: true,
  get: function () {
    return _invariant.setVerbosity;
  }
});
Object.defineProperty(exports, "throwServerError", {
  enumerable: true,
  get: function () {
    return _index5.throwServerError;
  }
});
Object.defineProperty(exports, "toPromise", {
  enumerable: true,
  get: function () {
    return _index5.toPromise;
  }
});
var _ApolloClient = require("apollo-stack-hubspot/@apollo/client/core/ApolloClient");
var _ObservableQuery = require("apollo-stack-hubspot/@apollo/client/core/ObservableQuery");
var _networkStatus = require("apollo-stack-hubspot/@apollo/client/core/networkStatus");
var _types = require("apollo-stack-hubspot/@apollo/client/core/types");
Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _types[key];
    }
  });
});
var _index = require("apollo-stack-hubspot/@apollo/client/errors/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/cache/index");
var _types2 = require("apollo-stack-hubspot/@apollo/client/cache/inmemory/types");
Object.keys(_types2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _types2[key];
    }
  });
});
var _index3 = require("apollo-stack-hubspot/@apollo/client/link/core/index");
Object.keys(_index3).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _index3[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _index3[key];
    }
  });
});
var _index4 = require("apollo-stack-hubspot/@apollo/client/link/http/index");
Object.keys(_index4).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _index4[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _index4[key];
    }
  });
});
var _index5 = require("apollo-stack-hubspot/@apollo/client/link/utils/index");
var _index6 = require("apollo-stack-hubspot/@apollo/client/utilities/index");
var _invariant = require("apollo-stack-hubspot/internal/ts-invariant/lib/invariant");
var _index7 = require("apollo-stack-hubspot/internal/graphql-tag/lib/index");
/* Link */
/* Supporting */
// The verbosity of invariant.{log,warn,error} can be controlled globally
// (for anyone using the same ts-invariant package) by passing "log",
// "warn", "error", or "silent" to setVerbosity ("log" is the default).
// Note that all invariant.* logging is hidden in production.
(0, _invariant.setVerbosity)(process.env.NODE_ENV !== "production" ? "log" : "silent");
// Note that importing `gql` by itself, then destructuring
// additional properties separately before exporting, is intentional.
// Due to the way the `graphql-tag` library is setup, certain bundlers
// can't find the properties added to the exported `gql` function without
// additional guidance (e.g. Rollup - see
// https://rollupjs.org/guide/en/#error-name-is-not-exported-by-module).
// Instead of having people that are using bundlers with `@apollo/client` add
// extra bundler config to help `graphql-tag` exports be found (which would be
// awkward since they aren't importing `graphql-tag` themselves), this
// workaround of pulling the extra properties off the `gql` function,
// then re-exporting them separately, helps keeps bundlers happy without any
// additional config changes.