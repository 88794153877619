"use strict";
"use es6";

// A version of Array.isArray that works better with readonly arrays.
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isArray = void 0;
exports.isNonEmptyArray = isNonEmptyArray;
var isArray = exports.isArray = Array.isArray;
function isNonEmptyArray(value) {
  return Array.isArray(value) && value.length > 0;
}