"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AutoCleanedStrongCache", {
  enumerable: true,
  get: function () {
    return _caches.AutoCleanedStrongCache;
  }
});
Object.defineProperty(exports, "AutoCleanedWeakCache", {
  enumerable: true,
  get: function () {
    return _caches.AutoCleanedWeakCache;
  }
});
Object.defineProperty(exports, "cacheSizes", {
  enumerable: true,
  get: function () {
    return _sizes.cacheSizes;
  }
});
var _caches = require("apollo-stack-hubspot/@apollo/client/utilities/caching/caches");
var _sizes = require("apollo-stack-hubspot/@apollo/client/utilities/caching/sizes");