"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  ApolloProvider: true,
  ApolloConsumer: true,
  getApolloContext: true,
  resetApolloContext: true,
  DocumentType: true,
  operationName: true,
  parser: true,
  createQueryPreloader: true
};
Object.defineProperty(exports, "ApolloConsumer", {
  enumerable: true,
  get: function () {
    return _index2.ApolloConsumer;
  }
});
Object.defineProperty(exports, "ApolloProvider", {
  enumerable: true,
  get: function () {
    return _index2.ApolloProvider;
  }
});
Object.defineProperty(exports, "DocumentType", {
  enumerable: true,
  get: function () {
    return _index4.DocumentType;
  }
});
Object.defineProperty(exports, "createQueryPreloader", {
  enumerable: true,
  get: function () {
    return _createQueryPreloader.createQueryPreloader;
  }
});
Object.defineProperty(exports, "getApolloContext", {
  enumerable: true,
  get: function () {
    return _index2.getApolloContext;
  }
});
Object.defineProperty(exports, "operationName", {
  enumerable: true,
  get: function () {
    return _index4.operationName;
  }
});
Object.defineProperty(exports, "parser", {
  enumerable: true,
  get: function () {
    return _index4.parser;
  }
});
Object.defineProperty(exports, "resetApolloContext", {
  enumerable: true,
  get: function () {
    return _index2.resetApolloContext;
  }
});
require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/react/context/index");
var _index3 = require("apollo-stack-hubspot/@apollo/client/react/hooks/index");
Object.keys(_index3).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _index3[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _index3[key];
    }
  });
});
var _index4 = require("apollo-stack-hubspot/@apollo/client/react/parser/index");
var _createQueryPreloader = require("apollo-stack-hubspot/@apollo/client/react/query-preloader/createQueryPreloader");
var _types = require("apollo-stack-hubspot/@apollo/client/react/types/types");
Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _types[key];
    }
  });
});