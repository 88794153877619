"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDirectiveNames = getDirectiveNames;
exports.getInclusionDirectives = getInclusionDirectives;
exports.hasAnyDirectives = exports.hasAllDirectives = void 0;
exports.hasClientExports = hasClientExports;
exports.hasDirectives = hasDirectives;
exports.shouldInclude = shouldInclude;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _visitor = require("graphql/language/visitor");
function shouldInclude(_a, variables) {
  var directives = _a.directives;
  if (!directives || !directives.length) {
    return true;
  }
  return getInclusionDirectives(directives).every(function (_a) {
    var directive = _a.directive,
      ifArgument = _a.ifArgument;
    var evaledValue = false;
    if (ifArgument.value.kind === "Variable") {
      evaledValue = variables && variables[ifArgument.value.name.value];
      (0, _index.invariant)(evaledValue !== void 0, 70, directive.name.value);
    } else {
      evaledValue = ifArgument.value.value;
    }
    return directive.name.value === "skip" ? !evaledValue : evaledValue;
  });
}
function getDirectiveNames(root) {
  var names = [];
  (0, _visitor.visit)(root, {
    Directive: function (node) {
      names.push(node.name.value);
    }
  });
  return names;
}
var hasAnyDirectives = exports.hasAnyDirectives = function hasAnyDirectives(names, root) {
  return hasDirectives(names, root, false);
};
var hasAllDirectives = exports.hasAllDirectives = function hasAllDirectives(names, root) {
  return hasDirectives(names, root, true);
};
function hasDirectives(names, root, all) {
  var nameSet = new Set(names);
  var uniqueCount = nameSet.size;
  (0, _visitor.visit)(root, {
    Directive: function (node) {
      if (nameSet.delete(node.name.value) && (!all || !nameSet.size)) {
        return _visitor.BREAK;
      }
    }
  });
  // If we found all the names, nameSet will be empty. If we only care about
  // finding some of them, the < condition is sufficient.
  return all ? !nameSet.size : nameSet.size < uniqueCount;
}
function hasClientExports(document) {
  return document && hasDirectives(["client", "export"], document, true);
}
function isInclusionDirective(_a) {
  var value = _a.name.value;
  return value === "skip" || value === "include";
}
function getInclusionDirectives(directives) {
  var result = [];
  if (directives && directives.length) {
    directives.forEach(function (directive) {
      if (!isInclusionDirective(directive)) return;
      var directiveArguments = directive.arguments;
      var directiveName = directive.name.value;
      (0, _index.invariant)(directiveArguments && directiveArguments.length === 1, 71, directiveName);
      var ifArgument = directiveArguments[0];
      (0, _index.invariant)(ifArgument.name && ifArgument.name.value === "if", 72, directiveName);
      var ifValue = ifArgument.value;
      // means it has to be a variable value if this is a valid @skip or @include directive
      (0, _index.invariant)(ifValue && (ifValue.kind === "Variable" || ifValue.kind === "BooleanValue"), 73, directiveName);
      result.push({
        directive: directive,
        ifArgument: ifArgument
      });
    });
  }
  return result;
}