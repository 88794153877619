"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripTypename = stripTypename;
var _omitDeep = require("apollo-stack-hubspot/@apollo/client/utilities/common/omitDeep");
function stripTypename(value) {
  return (0, _omitDeep.omitDeep)(value, "__typename");
}