"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toPromise = toPromise;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
function toPromise(observable) {
  var completed = false;
  return new Promise(function (resolve, reject) {
    observable.subscribe({
      next: function (data) {
        if (completed) {
          process.env.NODE_ENV !== "production" && _index.invariant.warn(43);
        } else {
          completed = true;
          resolve(data);
        }
      },
      error: reject
    });
  });
}