"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBackgroundQuery = useBackgroundQuery;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var React = _interopRequireWildcard(require("react"));
var _useApolloClient = require("apollo-stack-hubspot/@apollo/client/react/hooks/useApolloClient");
var _index = require("apollo-stack-hubspot/@apollo/client/react/internal/index");
var _index2 = require("apollo-stack-hubspot/@apollo/client/react/hooks/internal/index");
var _useSuspenseQuery = require("apollo-stack-hubspot/@apollo/client/react/hooks/useSuspenseQuery");
var _index3 = require("apollo-stack-hubspot/@apollo/client/cache/index");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function useBackgroundQuery(query, options) {
  if (options === void 0) {
    options = Object.create(null);
  }
  return (0, _index2.wrapHook)("useBackgroundQuery", _useBackgroundQuery, (0, _useApolloClient.useApolloClient)(typeof options === "object" ? options.client : undefined))(query, options);
}
function _useBackgroundQuery(query, options) {
  var client = (0, _useApolloClient.useApolloClient)(options.client);
  var suspenseCache = (0, _index.getSuspenseCache)(client);
  var watchQueryOptions = (0, _useSuspenseQuery.useWatchQueryOptions)({
    client: client,
    query: query,
    options: options
  });
  var fetchPolicy = watchQueryOptions.fetchPolicy,
    variables = watchQueryOptions.variables;
  var _a = options.queryKey,
    queryKey = _a === void 0 ? [] : _a;
  // This ref tracks the first time query execution is enabled to determine
  // whether to return a query ref or `undefined`. When initialized
  // in a skipped state (either via `skip: true` or `skipToken`) we return
  // `undefined` for the `queryRef` until the query has been enabled. Once
  // enabled, a query ref is always returned regardless of whether the query is
  // skipped again later.
  var didFetchResult = React.useRef(fetchPolicy !== "standby");
  didFetchResult.current || (didFetchResult.current = fetchPolicy !== "standby");
  var cacheKey = (0, _tslib.__spreadArray)([query, (0, _index3.canonicalStringify)(variables)], [].concat(queryKey), true);
  var queryRef = suspenseCache.getQueryRef(cacheKey, function () {
    return client.watchQuery(watchQueryOptions);
  });
  var _b = React.useState((0, _index.wrapQueryRef)(queryRef)),
    wrappedQueryRef = _b[0],
    setWrappedQueryRef = _b[1];
  if ((0, _index.unwrapQueryRef)(wrappedQueryRef) !== queryRef) {
    setWrappedQueryRef((0, _index.wrapQueryRef)(queryRef));
  }
  if (queryRef.didChangeOptions(watchQueryOptions)) {
    var promise = queryRef.applyOptions(watchQueryOptions);
    (0, _index.updateWrappedQueryRef)(wrappedQueryRef, promise);
  }
  // This prevents issues where rerendering useBackgroundQuery after the
  // queryRef has been disposed would cause the hook to return a new queryRef
  // instance since disposal also removes it from the suspense cache. We add
  // the queryRef back in the suspense cache so that the next render will reuse
  // this queryRef rather than initializing a new instance.
  React.useEffect(function () {
    // Since the queryRef is disposed async via `setTimeout`, we have to wait a
    // tick before checking it and adding back to the suspense cache.
    var id = setTimeout(function () {
      if (queryRef.disposed) {
        suspenseCache.add(cacheKey, queryRef);
      }
    });
    return function () {
      return clearTimeout(id);
    };
    // Omitting the deps is intentional. This avoids stale closures and the
    // conditional ensures we aren't running the logic on each render.
  });
  var fetchMore = React.useCallback(function (options) {
    var promise = queryRef.fetchMore(options);
    setWrappedQueryRef((0, _index.wrapQueryRef)(queryRef));
    return promise;
  }, [queryRef]);
  var refetch = React.useCallback(function (variables) {
    var promise = queryRef.refetch(variables);
    setWrappedQueryRef((0, _index.wrapQueryRef)(queryRef));
    return promise;
  }, [queryRef]);
  React.useEffect(function () {
    return queryRef.softRetain();
  }, [queryRef]);
  return [didFetchResult.current ? wrappedQueryRef : void 0, {
    fetchMore: fetchMore,
    refetch: refetch,
    subscribeToMore: queryRef.observable.subscribeToMore
  }];
}