"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterOperationVariables = filterOperationVariables;
var _tslib = require("apollo-stack-hubspot/internal/tslib/tslib");
var _visitor = require("graphql/language/visitor");
function filterOperationVariables(variables, query) {
  var result = (0, _tslib.__assign)({}, variables);
  var unusedNames = new Set(Object.keys(variables));
  (0, _visitor.visit)(query, {
    Variable: function (node, _key, parent) {
      // A variable type definition at the top level of a query is not
      // enough to silence server-side errors about the variable being
      // unused, so variable definitions do not count as usage.
      // https://spec.graphql.org/draft/#sec-All-Variables-Used
      if (parent && parent.kind !== "VariableDefinition") {
        unusedNames.delete(node.name.value);
      }
    }
  });
  unusedNames.forEach(function (name) {
    delete result[name];
  });
  return result;
}