"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ApolloConsumer", {
  enumerable: true,
  get: function () {
    return _ApolloConsumer.ApolloConsumer;
  }
});
Object.defineProperty(exports, "ApolloProvider", {
  enumerable: true,
  get: function () {
    return _ApolloProvider.ApolloProvider;
  }
});
Object.defineProperty(exports, "getApolloContext", {
  enumerable: true,
  get: function () {
    return _ApolloContext.getApolloContext;
  }
});
Object.defineProperty(exports, "resetApolloContext", {
  enumerable: true,
  get: function () {
    return _ApolloContext.resetApolloContext;
  }
});
require("apollo-stack-hubspot/@apollo/client/utilities/globals/index");
var _ApolloConsumer = require("apollo-stack-hubspot/@apollo/client/react/context/ApolloConsumer");
var _ApolloContext = require("apollo-stack-hubspot/@apollo/client/react/context/ApolloContext");
var _ApolloProvider = require("apollo-stack-hubspot/@apollo/client/react/context/ApolloProvider");