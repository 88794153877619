"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromPromise = fromPromise;
var _index = require("apollo-stack-hubspot/@apollo/client/utilities/index");
function fromPromise(promise) {
  return new _index.Observable(function (observer) {
    promise.then(function (value) {
      observer.next(value);
      observer.complete();
    }).catch(observer.error.bind(observer));
  });
}