"use strict";
"use es6";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Observable", {
  enumerable: true,
  get: function () {
    return _module.Observable;
  }
});
var _module = require("apollo-stack-hubspot/internal/zen-observable-ts/module");
require("apollo-stack-hubspot/internal/symbol-observable/index");
// This simplified polyfill attempts to follow the ECMAScript Observable
// proposal (https://github.com/zenparsing/es-observable)
// The zen-observable package defines Observable.prototype[Symbol.observable]
// when Symbol is supported, but RxJS interop depends on also setting this fake
// '@@observable' string as a polyfill for Symbol.observable.
var prototype = _module.Observable.prototype;
var fakeObsSymbol = "@@observable";
if (!prototype[fakeObsSymbol]) {
  // @ts-expect-error
  prototype[fakeObsSymbol] = function () {
    return this;
  };
}